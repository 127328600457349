<template>
  <div class="form-field" :class="class">
    <slot name="default-label" v-bind="{ inputFieldId, label, fieldLabel, fieldValue, required, formFieldClass}"></slot>
    <slot name="input-group" v-bind="{ inputFieldId, label, fieldLabel, fieldValue, onValueChanged, required, fieldRules, formFieldClass }">
      <div class="input-group">
        <slot name="prefix"><span class="input-group-text" v-if="prefix" v-html="prefix" /></slot>
        <slot name="form-floating" v-bind="{ inputFieldId, label, fieldLabel, fieldValue, onValueChanged, required, fieldRules, formFieldClass }">
          <span :class="label ? 'form-floating' : 'input'">
            <slot name="field" v-bind="{ inputFieldId, label, fieldLabel, fieldValue, onValueChanged, required, fieldRules, formFieldClass }"><Field :as="as" :type="type" v-model="fieldValue" :name="inputFieldId" :id="inputFieldId" class="form-control" :class="formFieldClass" :placeholder="fieldLabel || ''" :title="fieldLabel || ''" :required="required" :rules="fieldRules" v-bind="$attrs" /></slot>
            <slot name="label" v-bind="{ inputFieldId, label, fieldLabel, fieldValue, required, labelClass }"><label v-if="label" :for="inputFieldId" :class="labelClass">{{ fieldLabel }}</label></slot>
          </span>
        </slot>
        <slot name="suffix"><span class="input-group-text" v-if="suffix" v-html="suffix" /></slot>
      </div>
    </slot>
    <slot name="error-message" v-bind="{ inputFieldId, label, fieldLabel, fieldValue, required }"><ErrorMessage :name="inputFieldId" /></slot>
    <slot name="help-text" v-bind="{ inputFieldId, label, fieldLabel, fieldValue, helpText, required }"><div class="form-text" v-if="helpText" v-html="helpText" /></slot>
  </div>
</template>
<script>
import { getUid } from "../../utils/utils";
import { Field, ErrorMessage } from "vee-validate";
import formValidationMixin from "../../mixins/form-validation.mixin";

export default {
  name: 'form-field',
  components: { Field, ErrorMessage },
  mixins: [ formValidationMixin ],
  emits: [ 'update:modelValue' ],

  props: {
    modelValue: [ String, Number ],
    id: {
      type: String,
      required: false,
      default: null
    },
    as: {
      type: [String, Object],
      default: undefined,
    },
    type: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    class: {
      type: String,
      required: false,
      default: 'mb-3'
    },
    labelClass: {
      type: String,
      required: false,
      default: 'floating-label'
    },
    formFieldClass: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    prefix: {
      type: String,
      required: false,
      default: null
    },
    suffix: {
      type: String,
      required: false,
      default: null
    },
    helpText: {
      type: String,
      required: false,
      default: null
    },
    rules: {
      type: [ Array, Function ],
      required: false,
      default: null
    }
  },

  computed: {
    fieldValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
    inputFieldId() {
      if(this.id) {
        return this.id;
      }
      return `field-` + getUid();
    },
    fieldLabel() {
      if(this.label) {
        if(this.required) {
          return this.label + '*';
        }
        return this.label;
      }
      return '';
    },
    fieldRules() {
      const rules = [];
      if(this.required) {
        rules.push((value) => {
          return this.isRequiredField(value);
        });
      }
      if(this.rules && Array.isArray(this.rules)) {
        for(let r in this.rules) {
          rules.push(this.rules[r]);
        }
      } else if(this.rules && typeof this.rules == 'function') {
        rules.push(this.rules);
      }
      if(rules.length > 0) {
        return (value, args) => {
          for(let r in rules) {
            const result = rules[r](value, args);
            if(result !== true) {
              return result;
            }
          }
          return true;
        };
      }
      return null;
    }
  },

  data() {
    return {
      field: null
    }
  },

  methods: {
    onValueChanged(value) {
      this.$emit('update:modelValue', value);
    }
  }
}
</script>