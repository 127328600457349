<template>
  <div class="start-day-select mt-3">
    <div class="d-flex gap-2 mb-3" v-for="startDayRow in startDayRows">
      <button class="btn btn-select-start-day" :class="isSelected(startDay) ? 'selected' : ''" :disabled="isDisabled(startDay)" @click.prevent="$emit('update:modelValue', startDay)" v-for="startDay in startDayRow">
        {{ startDay }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'start-day-select',
  emits: [ 'update:modelValue' ],
  props: {
    modelValue: String,
    startDays: {
      type: Array,
      required: false,
      default: [ '4', '8', '15', '22', '29', '43', '92', '183' ]
    },
    disabledStartDays: {
      type: Array,
      required: false,
      default: []
    },
    startDaysPerRow: {
      type: Number,
      required: false,
      default: 5
    },
  },

  computed: {
    startDayRows() {
      const startDayRows = [];
      for(let i = 0; i < this.startDays.length; i += this.startDaysPerRow) {
        startDayRows.push(this.startDays.slice(i, i + this.startDaysPerRow))
      }
      return startDayRows;
    }
  },
  methods: {
    isDisabled(startDay) {
      return this.disabledStartDays.indexOf(startDay) !== -1;
    },

    isSelected(startDay) {
      return this.modelValue == startDay;
    }
  }
}
</script>