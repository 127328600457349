<template>
  <form-field v-bind="$props">
    <template #default-label="{ inputFieldId, fieldLabel }">
      <label v-if="fieldLabel" :for="inputFieldId">{{ fieldLabel }}</label>
    </template>
    <template #form-floating="{ inputFieldId, label, fieldLabel, required, fieldRules }">
      <Field as="select" v-model="fieldValue" class="form-select" :name="inputFieldId" :id="inputFieldId" :required="required" :rules="fieldRules">
        <option v-if="placeholder" value="">{{ placeholder }}</option>
        <option v-for="option in options" :value="option.value">{{ option.label }}</option>
      </Field>
    </template>
  </form-field>
</template>
<script>
import FormField from "../form-field.vue";

export default {
  name: 'select-field',
  components: {FormField},
  extends: FormField,
  emits: [ 'update:modelValue' ],

  props: {
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    options: {
      type: Array,
      required: true
    }
  },

  computed: {
    fieldValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  }
}
</script>