<template>

<hr>
<div id="fixed-vorschlagbar" class="fixed-vorschlagbar w-100 position-fixed">
  <div class="vwa-container">
        
        <div class="balls-wrapper d-flex align-items-center mx-5" >

          <div class="ball-wrapper ball-1">
            <div class="ball" :class="currentSubstep === 0 ? 'superactive' : 'active'"></div>
          </div>
          <div class="progress-bar-item progress-line w-100 mx-2"></div>
          <div class="ball-wrapper ball-2">
            <div class="ball" :class="currentSubstep === 1 ? 'superactive' : ''"></div>
          </div>
        </div>  

          <div id="vorschlag-progress-bar-top" class="progress-bar-top vorschlag-progress-bar-top d-flex flex-row justify-content-between align-items-center mt-3 mb-4">
            <div id="vorschlag-progress-bar-schnellerfassung" class="progress-bar-item progress-title" :class="currentSubstep === 0 ? 'active' : ''">
              <span class="step-title">Schnellerfassung</span>
            </div>
            
            <div id="vorschlag-progress-bar-zusatzbausteine" class="progress-bar-item progress-title" :class="currentSubstep === 1 ? 'active' : ''">
              <span class="step-title">Zusatzbausteine</span>
            </div>
          </div>

    </div>
  </div>
  <div id="fixed-vorschlagbar-spacer"></div>

  

  <template v-if="currentSubstep === 0">

    <vwa-jumbotron headline="Jetzt Vorschlag berechnen ..." />



    <div id="step-vorschlag" class="step-form-content">

      <div class="vwa-container">
        <div class="row">
          <div class="col-12">
          <h2>Ihre Daten</h2>

        <form-field type="date" v-model="form.birthDate" label="Geburtstag" :required="true" @focusout="onBirthDateLeave" :min="geburtstagRange.min" :max="geburtstagRange.max" :rules="validateGeburtstag" />

        <form-check v-model="form.taetigkeit" :options="taetigkeitOptions" label="Tätigkeit" type="radio" @change="onUpdateCustomerType" />

        <form-check v-if="form.taetigkeit == 'arbeitnehmer'" v-model="form.krankengeldBestandskunde" :options="krankengeldOptions" label="Ich habe bereits bei der DKV ein Krankentagegeld abgeschlossen" type="radio" :inline="true" @change="onUpdateCustomerType" />

        <!--template v-if="form.taetigkeit == 'arbeitnehmer'">
          <h2>Geschäfts- / Arbeitgebername</h2>
          <div class="form-group d-flex gap-2 mb-3">
            <form-field v-model="form.arbeitgeber.name" label="Geschäfts- / Arbeitgebername" class="flex-grow-1" :required="true" />
          </div>
          <div class="form-group d-flex gap-2">
            <form-field v-model="form.arbeitgeber.plz" label="PLZ" :required="true" />
            <form-field class="flex-grow-1" v-model="form.arbeitgeber.ort" label="Ort" :required="true" />
          </div>
          <div class="form-group d-flex gap-2 mb-3">
            <form-field v-model="form.arbeitgeber.strasse" label="Straße / Hausnummer (keine Postfach- oder c/o-Adresse)" class="flex-grow-1" :required="true" />
          </div>
          <div class="form-group d-flex gap-2 mb-3">
            <form-field v-model="form.arbeitgeber.telefon" label="Telefon geschäftlich (freiwillige Angabe)" class="flex-grow-1" :required="false" />
          </div>
        </template-->
        
          </div>
        </div>
      </div>
      <servicemodul v-if="defaultServiceModule" :services="form.services" :service="defaultServiceModule" :erasable="false" :auto-calculate="true" @recalculate="onRecalculateService" :start-days="startDays" />
    </div>



  </template>

  <template v-if="currentSubstep === 1">

    <vwa-jumbotron headline="Zusatzbausteine ergänzen" teaser="Optional" />

    <h2 class="text-center mb-3">
      Kombination von bis zu 3 Krankentagegeldern möglich
      <info-tooltip content="Hier können Sie auswählen, ob die von Ihnen gewählte Krankentagegeldabsicherungen im Zeitverlauf aufgestockt werden soll. Erfahrungsgemäß wird, je länger ein krankheitsbedingter Ausfall dauert, eine höhere Absicherung benötigt. Es können bis zu zwei weitere Startpunkte für den Beginn einer weiteren Krankentagegeldzahlung festgelegt werden und in Summe kann pro Monat maximal ein Krankentagegeld von 520 € abgesichert werden." icon="bi-info-circle" />
    </h2>
    <!--<h3 class="text-center">Ihr Leistungsbaustein:</h3>-->
    
      <servicemodul v-for="servicemodul in services" :services="form.services" :service="servicemodul" :erasable="!servicemodul.default" @recalculate="onRecalculateService" :start-days="startDays" />
    
    <vwa-service-chart :form="form" />
  </template>
</template>
<script>
import vwaFormStepMixin from "../../../mixins/vwa-form-step.mixin";
import FormField from "../../form/form-field.vue";
import FormCheck from "../../form/input/form-check.vue";
import Servicemodul from "../components/servicemodul.vue";
import ApiClient from "../../../utils/api-client";
import {isEmpty} from "../../../utils/utils";
import VwaJumbotron from "../components/vwa-jumbotron.vue";
import VwaServiceChart from "../components/vwa-service-chart.vue";
import InfoTooltip from "../components/info-tooltip.vue";

export default {
  name: 'vorschlag-step',
  components: {InfoTooltip, VwaServiceChart, VwaJumbotron, Servicemodul, FormCheck, FormField},
  mixins: [ vwaFormStepMixin ],

  watch: {
    'form.birthDate': 'onBirthDateLeave',
  },

  computed: {
    taetigkeitOptions() {
      return [
        {
          value: 'freiberufler',
          label: 'Freiberufler'
        },
        {
          value: 'arbeitnehmer',
          label: 'Arbeitnehmer'
        }
      ]
    },
    krankengeldOptions() {
      return [
        {
          value: 'ja',
          label: 'Ja'
        },
        {
          value: 'nein',
          label: 'Nein'
        }
      ]
    },
    geburtstagRange() {
      const minYear = new Date().getFullYear() - 100;
      const maxYear = new Date().getFullYear() - 20;
      return { min: `${minYear}-01-01`, max: `${maxYear}-12-31` }
    },
    defaultServiceModule() {
      if(this.form.age && this.form.age >= this.minAge && this.form.age <= this.maxAge && !isEmpty(this.form.taetigkeit) && (this.form.taetigkeit == 'freiberufler' || this.form.taetigkeit == 'arbeitnehmer' && !isEmpty(this.form.krankengeldBestandskunde))) {
        return this.services.filter((serviceModul) => serviceModul.default)[0] || null;
      }
      return null;
    }
  },

  data() {
    return {
      calculated: false,
      currentSubstep: 0,
      minAge: 20,
      maxAge: 100
    }
  },

  created() {
    if(typeof this.form.services === "undefined") {
      this.form.services = {};
    }
    if(typeof this.form.arbeitgeber === "undefined") {
      this.form.arbeitgeber = {};
    }
    this.currentSubstep = this.stepControl.getState('currentVorschlagSubStep', 0);
    this.updateSubstep(this.currentSubstep);
    this.createServices();
    this.onBirthDateLeave();
  },

  methods: {
    showZahlbeitrag() {
      return this.currentSubstep > 0;
    },
    updateSubstep(substep) {
      this.currentSubstep = substep;
      this.$emit('update-prev-step-disabled', substep === 0);
      this.stepControl.setState('currentVorschlagSubStep', substep);
    },
    onUpdateCustomerType() {
      this.form.services = {};
      this.createServices();

      if(this.form.taetigkeit == "freiberufler") {
        this.form.customerType = 'KTAA';
      } else {
        if(this.form.krankengeldBestandskunde == 'ja') {
          this.form.customerType = 'KTC';
        } else if(this.form.krankengeldBestandskunde == 'nein') {
          this.form.customerType = 'KTAG';
        } else {
          this.form.customerType = '';
        }
      }
    },
    createServices() {
      for(let m in this.services) {
        const servicemodul = this.services[m];
        console.log('servicemodul', servicemodul);
        if(typeof this.form.services[m] === "undefined") {
          this.form.services[m] = {
            id: servicemodul.id,
            show: false,
            selected: servicemodul.default,
            default: servicemodul.default,
            sickPay: servicemodul.minSickPay
          };
        }
      }
    },
    onBirthDateLeave() {
      const today = new Date();
      const birthDate = new Date(this.form.birthDate);
      this.form.age = today.getFullYear() - birthDate.getFullYear();
    },
    showLeistungsmodul(id) {
      this.form.services[id].show = true;
    },
    onRecalculateService(e) {
      const apiClient = new ApiClient();
      apiClient.calculateServices(this.formId, this.form.age || null, this.form.customerType || null, this.form.services)
        .then(({ data }) => {
          this.calculated = data.valid;
          for(let s in data.services) {
            const service = data.services[s];
            this.form.services[service.id].totalSickPay = service.totalSickPay || 0;
            this.form.services[service.id].contribution = service.contribution || 0;
            this.form.services[service.id].totalContribution = service.totalContribution || 0;
            this.form.services[service.id].coverage = service.coverage || 0;
            this.form.services[service.id].totalCoverage = service.totalCoverage || 0;
          }
          this.form.infoText = data.infoText;
          this.form.totalContribution = data.totalContribution;
          this.form.totalCoverage = data.totalCoverage;
        });
    },
    goToNextStep() {
      if(this.currentSubstep === 0) {
        this.updateSubstep(1);
        return false;
      }
      return true;
    },
    goToPrevStep() {
      if(this.currentSubstep === 1) {
        this.updateSubstep(0);
        return false;
      }
      return true;
    },
    validateGeburtstag(value) {
      const today = new Date();
      const birthDate = new Date(value);
      const age = today.getFullYear() - birthDate.getFullYear();
      const minDate = new Date(this.geburtstagRange.min);
      const maxDate = new Date(this.geburtstagRange.max);
      if(age < this.minAge || age > this.maxAge) {
        return 'Bitte geben Sie ein Datum zwischen dem ' + minDate.toLocaleDateString('de-DE') + ' und ' + maxDate.toLocaleDateString('de-DE') + ' ein';
      }
      return true;
    }
  }
}
</script>