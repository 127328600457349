<template>
  <div class="mb-3 frage-inner">
    <slot name="label" v-bind="{ id, label, required }"><label v-if="label" class="form-label d-block"><span v-html="label" /><span class="required-label" v-if="required && showRequired">*</span></label></slot>
    <slot name="options" v-bind="{ id, options, label, inline, type, required, onChange, onClick, isChecked }">
      
        <div v-for="option in options" class="form-check" :class="inline ? 'form-check-inline' : ''">
          <input :name="id" :id="`${id}-${option.value}`" class="form-check-input" :type="type" :value="option.value" @change="onChange" @click="onClick" :checked="isChecked(option.value)" />
          <label :for="`${id}-${option.value}`" class="form-check-label" v-html="option.label" />
          <info-tooltip v-if="option.infoTooltip" :content="option.infoTooltip" />
          <slot :name="`option-suffix-${option.value}`" v-bind="{ id, option, options, label, inline, type, required }"></slot>
        </div>
      
    </slot>
    <slot name="error-msg" v-bind="{ id, options, label, inline, type, required }">
      <ErrorMessage :name="id" />
    </slot>
  </div>
</template>
<script>
import {getUid, isEmpty} from "../../../utils/utils";
import {Field, ErrorMessage, useField} from "vee-validate";
import InfoTooltip from "../../vwa-form/components/info-tooltip.vue";

export default {
  name: 'form-check',
  components: {InfoTooltip, Field, ErrorMessage },
  emits: [ "update:modelValue", "reset" ],
  props: {
    modelValue: [ String, Array ],
    id: {
      type: String,
      required: false,
      default: () => 'form-check-' + getUid(),
    },
    type: {
      type: String,
      required: false,
      default: 'checkbox'
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: undefined
    },
    inline: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: true
    },
    showRequired: {
      type: Boolean,
      required: false,
      default: true
    },
    resetValue: {
      required: false,
      default: null
    }
  },

  setup(props, { emit, attrs }) {
    const onValidate = () => {
      if(isEmpty(props.modelValue)) {
        return 'Dieses Feld ist ein Pflichtfeld.';
      }
      return true;
    };
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      validate,
      meta,
    } = useField(props.id, onValidate, {
      initialValue: props.modelValue,
      valueProp: props.modelValue
    });

    const onChange = (event, input) => {
      const value = 0;
      console.log('onChange', { event, input });
      emit('update:value', value);
    }

    return {
      onChange,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
    };
  },
  methods: {
    isChecked(value) {
      if(this.type == 'radio') {
        return this.modelValue == value;
      }
      const modelValue = this.modelValue || [];
      return modelValue.indexOf(value) !== -1;
    },
    onChange($event) {
      if(this.type == 'radio') {
        this.$emit('update:modelValue', $event.target.value);
      } else if(this.type == 'checkbox') {
        let value = this.modelValue;
        if(!value) {
          value = [];
        }
        if(!Array.isArray(value)) {
          value = [ value ];
        }
        const valueIndex = value.indexOf($event.target.value);
        if($event.target.checked && valueIndex === -1) {
          value.push($event.target.value);
        } else if(!$event.target.checked && valueIndex !== -1) {
          value.splice(valueIndex, 1);
        }
        this.$emit('update:modelValue', value);
      }
    },
    onClick($event) {
      if(this.type.toLowerCase() != 'radio') {
        return;
      }
      if($event.target.value == this.modelValue) {
        this.$emit('update:modelValue', this.resetValue);
        this.$emit('reset');
      }
    },
    openInfoTooltip(infoTooltip, element) {
      const tooltip = new bootstrap.Tooltip(element, {
        html: true,
        trigger: 'focus',
        title: infoTooltip
      });
      tooltip.show();
    },
  }
}
</script>