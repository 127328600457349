<template>

<div id="fixed-gesundbar" class="fixed-gesundbar w-100 position-fixed">

  <div class="container">
    <div class="gesundheit-progress  mb-3">
      <div class="progress" role="progressbar" aria-label="Basic example" :aria-valuenow="currentGesundheitBlock === 0 ? 0 : 50" aria-valuemin="0" aria-valuemax="100">
        <div class="progress-bar" :style="`width: ${currentGesundheitBlock === 0 ? 0 : 50}%`"></div>
      </div>

      <div class="d-flex justify-content-between mt-1">
        <div><strong>Gesundheitseinschätzung</strong></div>
        <div>Fortschritt: <span class="progress-value">{{ (currentGesundheitBlock === 0 ? 0 : 50) }}%</span> </div>
      </div>
    </div>
  </div>

</div>
<div id="fixed-gesundbar-spacer"></div>

  <vwa-jumbotron headline="Gesundheitseinschätzung" />

  <div class="vwa-container">

    <div class="gesundheit-koerper" v-if="currentGesundheitBlock === 0">
      <p>Bitte erfassen Sie Ihre Körpergröße und -gewicht.</p>

      <form-field type="number" v-model="form.gesundheitsfragen.Groesse_Person1" label="Körpergröße" :required="true" suffix="cm" />
      <form-field type="number" v-model="form.gesundheitsfragen.Gewicht_Person1" label="Körpergewicht" :required="true" suffix="kg" />
    </div>
    <div class="gesundheit-fragen" v-if="currentGesundheitBlock === 1">

  <!--
      <div class="d-flex flex-row-reverse mb-3">
        <div class="form-check">
          <label for="all-no">alle nein</label>
          <br/>
          <input type="radio" class="form-check-input" id="all-no" v-model="allNo" :value="true" @change="onChangeNoAll">
        </div>
        
      </div>
  -->

      <div class="gesundheit-frage">
        <div class="mb-3 frage-inner">
          <label class="form-label d-block"></label>
          <div class="frage-optionen top">
            <div class="form-check"></div>
            <div class="form-check-separator"></div>
            <div class="form-check">
              <div class="frage-optionen-top-label" for="all-no">alle Nein</div>
              <input type="radio" class="form-check-input" id="all-no" v-model="allNo" :value="true" @change="onChangeNoAll">
            </div>
          </div>
        </div>
      </div>


      <div class="gesundheit-frage">
        <div class="mb-3 frage-inner">
          <label class="form-label d-block"></label>
          <div class="frage-optionen">
            <div class="form-check">ja</div>
            <div class="form-check-separator"></div>
            <div class="form-check">nein</div>
          </div>
        </div>
      </div>

      <div class="gesundheit-frage" v-for="(gesundheitsfrage, index) in gesundheitsfragen">
        <form-check
            v-model="form.gesundheitsfragen[gesundheitsfrage.name]"
            :options="yesNoRadioOptions"
            :label="gesundheitsfrage.label"
            reset-value=""
            type="radio"
            :showRequired="false"
            @change="onChangeGesundheitsfrage(gesundheitsfrage, index)"
            @reset="onChangeGesundheitsfrage(gesundheitsfrage, index)"
            :inline="true">
          <template #options="{ id, options, label, inline, type, required, onChange, onClick, isChecked }">
            <div class="frage-optionen">
              <template v-for="option in options">
                <div class="form-check" :class="inline ? 'form-check-inlinee' : ''">
                  <input :name="id" :id="`${id}-${option.value}`" class="form-check-input" :type="type" :value="option.value" @change="onChange" @click="onClick" :checked="isChecked(option.value)" />
                  <!--<label :for="`${id}-${option.value}`" class="form-check-label" v-html="option.label" />-->
                  <info-tooltip v-if="option.infoTooltip" :content="option.infoTooltip" />
                  <slot :name="`option-suffix-${option.value}`" v-bind="{ id, option, options, label, inline, type, required }"></slot>
                </div>
                <div class="form-check-separator" v-if="option.value == 'ja'"><div class="stricher"></div></div>
              </template>
            </div>
          </template>
        </form-check>

        <div class="frage-option" v-if="gesundheitsfrage.number === 9 && form.gesundheitsfragen.G6_9_Person1 == 'ja'">
          <form-field class="long-number ms-3 mb-3" type="number" min="1" max="100" v-model="form.gesundheitsfragen.Behinderung_Person1" label="Grad der Beeinträchtigung/Behinderung" :required="true" suffix="%" />
        </div>

        <div class="frage-option" v-if="gesundheitsfrage.number === 10 && form.gesundheitsfragen.G6_10_Person1 == 'ja'">
          <form-field type="number" class="ms-3 mb-3" min="1" max="9" v-model="form.gesundheitsfragen.Schwangerschaftsmonat_Person1" label="Monat" :required="true" />
        </div>

        <div class="frage-option" v-if="gesundheitsfrage.number === 13 && form.gesundheitsfragen.G6_13_Person1 == 'ja'">
          <form-field type="number" class="ms-3 mb-3" v-model="form.gesundheitsfragen.Zahnverlust_Person1" label="Anzahl" :required="true" />
        </div>

        <gesundheit-details-block v-if="showGesundheitsDetails(gesundheitsfrage)" :details="getGesundheitsDetails(gesundheitsfrage)" />
      </div>
      <div class="stroker-bar"></div>
    </div>
  </div>

  <div class="modal" tabindex="-1" id="gesundheitInfoModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <p>Sehr geehrte Interessentin, sehr geehrter Interessent,</p>
          <p>
            Sie haben bei mehr als fünf Gesundheitsfragen angegeben, dass Vorerkrankungen/Behandlungen vorliegen.
          </p>
          <p>
            Erfahrungsgemäß führt dies zu Rückfragen beim Versicherer. Um Ihnen hier Ihren Aufwand so gering wie möglich zu halten,<br/>
            wird Ihr Antrag von uns ausgesteuert und Sie werden von einem Berater automatisch kontaktiert, bevor Ihr Antrag an die Versicherung übergeben wird.
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">schließen</button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import vwaFormStepMixin from "../../../mixins/vwa-form-step.mixin";
import { Field } from "vee-validate";
import FormCheck from "../../form/input/form-check.vue";
import FormField from "../../form/form-field.vue";
import GesundheitDetailsBlock from "../components/gesundheit-details-block.vue";
import VwaJumbotron from "../components/vwa-jumbotron.vue";

export default {
  name: 'gesundheit-step',
  components: {VwaJumbotron, GesundheitDetailsBlock, Field, FormField, FormCheck },
  mixins: [ vwaFormStepMixin ],

  watch: {
    gesundheitDetails(val) {
      console.trace(val);
    }
  },

  data() {
    return {
      infoModalShown: false,
      allNo: false,
      currentGesundheitBlock: 0,
      totalGesundheitsBlocks: 2,
      gesundheitsfragen: [
        {
          number: 1,
          details: true,
          name: 'G6_1_Person1',
          label: 'Hat in den letzten 5 Jahren ein Krankenhaus-, Heilstätten-, Kur- oder Sanatoriumsaufenthalt stattgefunden?'
        },
        {
          number: 2,
          details: true,
          name: 'G6_2_Person1',
          label: 'Haben in den letzten 5 Jahren Operationen (auch ambulant) stattgefunden?'
        },
        {
          number: 3,
          details: true,
          name: 'G6_3_Person1',
          label: 'Wurde in den letzten 5 Jahren eine Psychotherapie oder Suchtbehandlung durchgeführt?'
        },
        {
          number: 4,
          details: true,
          name: 'G6_4_Person1',
          label: 'Bestanden in den letzten 3 Jahren oder bestehen gegenwärtig Krankheiten, Beschwerden, Unfallfolgen, sonstige Gesundheitsstörungen oder haben Untersuchungen / Behandlungen stattgefunden?'
        },
        {
          number: 5,
          details: true,
          name: 'G6_5_Person1',
          label: 'Werden Arzneimittel (z. B. Tabletten, Salben) angewendet?<br/>Wenn ja, welche und aufgrund welcher Diagnose?'
        },
        {
          number: 6,
          details: true,
          name: 'G6_6_Person1',
          label: 'Ist eine ambulante / stationäre Behandlung oder Untersuchung angeraten oder beabsichtigt?'
        },
        {
          number: 7,
          details: true,
          name: 'G6_7_Person1',
          label: 'Wurde jemals eine HIV-Infektion festgestellt (z. B. durch einen AIDS-Test)?'
        },
        {
          number: 8,
          details: true,
          name: 'G6_8_Person1',
          label: 'Besteht eine Fehlsichtigkeit von 8 Dioptrien oder mehr?'
        },
        {
          number: 9,
          details: true,
          name: 'G6_9_Person1',
          label: 'Bestehen ein körperlicher / organischer Fehler (z. B. fehlende Organe, fehlende Gliedmaßen, Schwerhörigkeit), Körperimplantate / Prothesen (z. B. Kniegelenkersatz, Brustimplantate), eine chronische Erkrankung oder eine Minderung der Erwerbsfähigkeit bzw. ein Grad der Behinderung? Oder ist ein Antrag auf Anerkennung einer solchen Beeinträchtigung gestellt oder beabsichtigt zu stellen?<br/><strong>Wenn ja, bitte Bescheid beifügen</strong>'
        },
        {
          number: 10,
          details: true,
          name: 'G6_10_Person1',
          label: 'Besteht eine Schwangerschaft?<br/><strong>Wenn ja, bitte Monat eintragen</strong>'
        },
        /*{
          number: 11,
          details: true,
          name: 'G6_11_Person1',
          label: '<strong>Zusätzlich</strong> für Personen <strong>vor Vollendung des 4. Lebensjahrs</strong> zu beantworten: Geburtsgewicht kleiner 2000 Gramm?'
        },*/
        {
          number: 12,
          details: true,
          name: 'G6_12_Person1',
          label: 'Findet zurzeit eine Zahnbehandlung, die Anfertigung oder Erneuerung von Zahnersatz, eine Parodontosebehandlung oder eine Kiefer-(Zahn-)Regulierung statt, oder sind solche Maßnahmen beabsichtigt oder angeraten worden?'
        },
        {
          number: 13,
          details: true,
          name: 'G6_13_Person1',
          label: 'Fehlen Zähne, die noch nicht ersetzt sind (außer Milch- und Weisheitszähnen sowie Zähnen, bei denen die Lücken durch benachbarte Zähne geschlossen wurden)?<br/><strong>Wenn ja, bitte Anzahl angeben</strong>'
        },
        {
          number: 14,
          details: true,
          name: 'G6_14_Person1',
          label: 'Besteht eine Pflegebedürftigkeit oder wurde bereits ein Antrag auf Leistungen aus einerprivaten oder sozialen Pflegeversicherung gestellt? Oder ist eine solche Beantragung beabsichtigt?<br/>Ist Ihre gesundheitliche Selbstständigkeit bei Aktivitäten des täglichen Lebens beeinträchtigt?<br/>Oder sind Sie dazu auf Hilfe bzw. auf den Gebrauch von Hilfsmitteln (z. B. Gehstock, Rollator) angewiesen?<br/>Mindestens einer dieser sechs Lebensbereiche ist betroffen: 1. Mobilität (z. B. Gehhilfen) / 2. Kognitive und kommunikative Fähigkeiten / 3. Verhaltensweisen und psychische Problemlagen / 4. Selbstversorgung / 5. Umgang mit krankheits- oder therapiebedingten Anforderungen und Belastungen / 6. Gestaltung des Alltagslebens und sozialer Kontakte.'
        },
        {
          number: 15,
          details: false,
          name: 'G6_15_Person1',
          label: '<strong>Für Personen ab Vollendung des 16. Lebensjahres:</strong><p class="mt-2">Sind Sie werdende Mutter oder werdender Vater oder beabsichtigen Sie eine Adoption?*</p><small>* Wenn Ja: frühestmöglicher Versicherungsbeginn wird 6 Monate vordatiert.</small>'
        }
      ],
      maxDetailBlocks: 5,
      gesundheitsfrageDefaults: {
        'G6_1_Person1': null,
        'G6_2_Person1': null,
        'G6_3_Person1': null,
        'G6_4_Person1': null,
        'G6_5_Person1': null,
        'G6_6_Person1': null,
        'G6_7_Person1': null,
        'G6_8_Person1': null,
        'G6_9_Person1': null,
        'Behinderung_Person1': null,
        'G6_10_Person1': null,
        'Schwangerschaftsmonat_Person1': null,
        'Groesse_Person1': null,
        'Gewicht_Person1': null,
        'G6_13_Person1': null,
        'Zahnverlust_Person1': null,
        'G6_14_Person1': null,
        'Details_G11': {}
      }
    }
  },

  computed: {
    currentProgress() {
      return Math.round((this.currentGesundheitBlock + 1) * 100 / this.totalGesundheitsfragen);
    },
    gesundheitDetails() {
      return this.form.gesundheitsfragen.Details_G11;
    }
  },

  created() {
    this.form.gesundheitsfragen = { ...this.gesundheitsfrageDefaults, ...this.form.gesundheitsfragen };
    this.currentGesundheitBlock = 0;
    this.infoModalShown = false;
  },

  methods: {
    showGesundheitsDetails(gesundheitsfrage) {
      const name = gesundheitsfrage.name;
      const details = this.gesundheitDetails[name] || {};
      return details?.show === true && gesundheitsfrage.details;
    },
    showGesundheitsDetailsHinweis(gesundheitsfrage) {
      const name = gesundheitsfrage.name;
      const details = this.gesundheitDetails[name] || {};
      return details?.showHinweis === true;
    },
    getGesundheitsDetails(gesundheitsfrage) {
      const name = gesundheitsfrage.name;
      return this.gesundheitDetails[name];
    },
    onChangeGesundheitsfrage(gesundheitsfrage) {
      const name = gesundheitsfrage.name;
      const totalShowDetails = Object.values(this.gesundheitDetails).filter((gesundheitsfrage) => gesundheitsfrage.show).length;
      if(typeof this.gesundheitDetails[name] === 'undefined') {
        this.gesundheitDetails[name] = {
          details: gesundheitsfrage.number,
          show: false
        };
      }
      this.gesundheitDetails[name].show = (this.form.gesundheitsfragen[name] || '') == 'ja' && totalShowDetails < this.maxDetailBlocks;
      this.gesundheitDetails[name].showHinweis = (this.form.gesundheitsfragen[name] || '') == 'ja' && totalShowDetails >= this.maxDetailBlocks;
      this.updateShowInfoModal();
      if((this.form.gesundheitsfragen[name] || '') == 'ja') {
        this.allNo = false;
      }
    },
    onChangeNoAll($event) {
      if(!$event.target.checked) {
        return;
      }

      for(let i in this.gesundheitsfragen) {
        const name = this.gesundheitsfragen[i].name;
        this.form.gesundheitsfragen[name] = 'nein';
        if(typeof this.gesundheitDetails[name] !== 'undefined') {
          this.gesundheitDetails[name].show = false;
          this.gesundheitDetails[name].showHinweis = false;
        }
      }
    },
    goToNextStep() {
      this.currentGesundheitBlock++;
      //this._updateGesundheitsfrage();
      return this.currentGesundheitBlock >= this.totalGesundheitsBlocks;
    },
    goToPrevStep() {
      this.currentGesundheitBlock--;
      //this._updateGesundheitsfrage();
      return this.currentGesundheitBlock <= 0;
    },
    updateShowInfoModal() {
      const showModal = Object.values(this.gesundheitDetails).filter((gesundheitFrage) => gesundheitFrage.showHinweis).length > 0;
      if(showModal && !this.infoModalShown) {
        const infoModal = new bootstrap.Modal('#gesundheitInfoModal');
        infoModal.show();
      }
      console.log('showModal', showModal);
      this.infoModalShown = showModal;
    }
    /*_updateGesundheitsfrage() {
      const gesundheitsfrage = this.gesundheitsfragen[this.currentGesundheitsfrage] || null;
      if(!gesundheitsfrage) {
        return gesundheitsfrage;
      }
      this.onChangeGesundheitsfrage(gesundheitsfrage);
    }*/
  }
}
</script>