<template>
  <div class="gesundheit-details-block">

        <label class="form-label"><template v-if="details.details != 12">Art der Krankheit, Arzneimittel, Verletzung, Beschwerden, Untersuchung (Was wurde festgestellt?)</template><template v-if="details.details == 8">, Höhe der Dioptrienwerte?</template><template v-if="details.details == 12">Welche Maßnahmen?</template></label>
        <div class="d-flex gap-2 w-100">
          <div class="form-check mt-3">
            <Field type="checkbox" v-model="details.bekannt" value="ja" class="form-check-input" :name="`Details_bekanntDetails_G11${id}`" :id="`Details_bekanntDetails_G11${id}`" />
            <label class="form-check-label" :for="`Details_bekanntDetails_G11${id}`">sind der DKV bekannt (ansonsten bitte hier ausführen)</label>
          </div>
        </div>
        <Field as="textarea" class="form-control" v-model="details.ausfuehrung" :name="`Details_AusfuehrungDetails_G11${id}`" cols="50" rows="4" />


        <label class="form-label pt-3">Behandlungen / Beschwerden</label>
        <div class="d-block d-sm-flex ">
          <form-field type="date" v-model="details.von" label="Von" />
          <div style="width:1rem;height:1rem;"></div>
          <form-field type="date" v-model="details.bis" label="Bis" />
        </div>
        <div class="form-check">
          <Field type="checkbox" v-model="details.behandlungsfrei" value="ja" class="form-check-input" :name="`BehandlungsfreiDetails_G11${id}`" :id="`BehandlungsfreiDetails_G11${id}`" />
          <label class="form-check-label" :for="`BehandlungsfreiDetails_G11${id}`">Behandlungs-/Beschwerdefrei seit</label>
        </div>
        <form-field type="date" v-if="details.behandlungsfrei == 'ja'" v-model="details.behandlungsfreiSeit" label="Von" />

        <div class="form-check">
          <Field type="checkbox" v-model="details.pflegebeduerftig" value="ja" class="form-check-input" :name="`PflegebeduerftigDetails_G11${id}`" :id="`PflegebeduerftigDetails_G11${id}`" />
          <label class="form-check-label" :for="`PflegebeduerftigDetails_G11${id}`">Pflegebedürftig seit</label>
        </div>
        <form-field type="date" v-if="details.pflegebeduerftig == 'ja'" v-model="details.pflegebeduerftigSeit" label="Von" />
        <div class="form-group">
          <label class="form-label mt-3" :for="`Details_AuskunftDetails_G11${id}`">Wer kann Auskunft geben (Name, Anschrift der Ärzte, Krankenhäuser)?</label>
          <Field as="textarea" class="form-control" v-model="details.auskunft" :name="`Details_AuskunftDetails_G11${id}`" :id="`Details_AuskunftDetails_G11${id}`" cols="50" rows="2" />
        </div>
      </div>


</template>
<script>
import FormField from "../../form/form-field.vue";
import { Field } from "vee-validate";
import {getUid} from "../../../utils/utils";

export default {
  name: 'gesundheit-details-block',
  components: { Field, FormField },

  props: {
    id: {
      type: String,
      required: false,
      default: () => 'gesundheit-details-block-' + getUid(),
    },
    details: {
      type: Object,
      required: true
    }
  }
}
</script>