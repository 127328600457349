import mitt from "mitt";

export default class StepControl {
    constructor(id, steps) {
        this.id = id;
        this.steps = steps;
        this.stepHistory = [];
        this.states = {};
        this.emitter = mitt();
        this.setCurrentStepIndex(0);
    }

    getId() {
        return this.id;
    }

    getEmitter() {
        return this.emitter;
    }

    getVisibleSteps() {
        const steps = this.steps.filter((step) => {
            if(typeof step.visible == 'undefined') {
                return true;
            }
            if(typeof step.visible == 'function') {
                return (step.visible)();
            }
            return step.visible === true
        });
        steps.map((step, i) => {
            step.firstStep = i == 0;
            step.lastStep = i == steps.length - 1;
        });
        return steps;
    }

    isFirstStep(step = null) {
        return (step ? step : this.getCurrentStep())?.firstStep || false;
    }

    isLastStep(step = null) {
        return (step ? step : this.getCurrentStep())?.lastStep || false;
    }

    getCurrentStepId() {
        const visibleSteps = this.getVisibleSteps();
        for(let p in visibleSteps) {
            if(visibleSteps[p].current === true) {
                return visibleSteps[p].id;
            }
        }
        return null;
    }

    getCurrentStepIndex() {
        return this.findStepIndex(this.getCurrentStepId());
    }

    getCurrentStep() {
        const visibleSteps = this.getVisibleSteps();
        for(let p in visibleSteps) {
            if(visibleSteps[p].current === true) {
                return visibleSteps[p];
            }
        }
        return null;
    }

    setCurrentStepId(id) {
        const visibleSteps = this.getVisibleSteps();
        for(let p in visibleSteps) {
            visibleSteps[p].active = false;
            visibleSteps[p].current = visibleSteps[p].id === id;
            if(visibleSteps[p].id === id) {
                this.emitter.emit('step-changed', { step: visibleSteps[p], index: p, stepControl: this });
            }
        }
        const stepIndex = this.findStepIndex(id, visibleSteps);
        if(stepIndex >= 0) {
            visibleSteps[stepIndex].visited = true;
            for(let i = 0; i <= stepIndex; i++) {
                visibleSteps[i].active = true;
            }
        }
    }

    setCurrentStepIndex(index) {
        const visibleSteps = this.getVisibleSteps();
        const currentStep = visibleSteps[index] || null;
        if(currentStep) {
            this.setCurrentStepId(currentStep.id);
        }
    }

    findStepIndex(id, visibleSteps = null) {
        if(!visibleSteps) {
            visibleSteps = this.getVisibleSteps();
        }
        return visibleSteps.findIndex((step) => step.id == id)
    }

    findStepId(stepIndex) {
        return this.getVisibleSteps()[stepIndex]?.id || null;
    }

    isInStepHistory(id) {
        return this.stepHistory.indexOf(id) >= 0;
    }

    pushStepToHistory() {
        this.stepHistory.push(this.getCurrentStepId());
    }

    goToPrevStep() {
        if(this.stepHistory.length > 0) {
            this.setCurrentStepId(this.stepHistory.pop());
        }
    }

    goToStep(id) {
        this.setCurrentStepId(id);
        const currentStepIndex = this.stepHistory.indexOf(this.getCurrentStepId());
        if(currentStepIndex >= 0) {
            this.stepHistory.splice(currentStepIndex);
        }
    }

    hasState(name) {
        return typeof this.states[name] !== "undefined";
    }

    getState(name, defaultValue = null) {
        return this.states[name] || defaultValue;
    }

    setState(name, value) {
        this.states[name] = value;
    }

    deleteState(name) {
        if(!this.hasState(name)) {
            return;
        }
        delete this.states[name];
    }
}