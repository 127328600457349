
export function isEmpty(value) {
    if(Array.isArray(value)) {
        return value.length === 0;
    }
    return value === null || value === undefined || value === '';
}

export function getUid(length=12) {
    return parseInt(Math.ceil(Math.random() * Date.now()).toPrecision(length).toString().replace(".", ""));
}

export function formatCurrency(value, emptyValue = '-,- &euro;') {
    if(isEmpty(value) || value === 0 || value === 0.0) {
        return emptyValue;
    }
    const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
    });
    return formatter.format(value);
}

export function toggleRadio($event, value) {

}