<template>
  <div v-if="stepControl" id="vorschlag-progress-bar-top" class="progress-bar-top vorschlag-progress-bar-top d-flex flex-row justify-content-between align-items-center my-4">
    <template v-for="(step, i) in stepControl.getVisibleSteps()">
      <div :id="`vorschlag-progress-bar-item-${step.id}`" class="progress-bar-item progress-title me-2" :class="step.current ? 'active' : ''">
        <!--span role="button" v-if="stepControl.isInStepHistory(step.id)" @click.prevent="stepControl.goToStep(step.id)">{{ step.title }}</span>
        <template v-else>{{ step.title }}</template-->
        <span class="step-number me-1">{{ step.number }}</span>
        <span class="step-title">{{ step.title }}</span>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'vorschlag-step-control',

  props: {
    stepControl: {
      type: Object,
      required: true
    }
  }
}
</script>