import { createApp } from 'vue';
import VueApp from './App';
import vwaForm from "../components/vwa-form/vwa-form.vue";
import formRow from "../components/form/form-row.vue";
import numberInput from "../components/form/input/number-input.vue";

export default class App {

    constructor(config) {
        window.app = this;
        this.config = config;
        this.lastHeight = document.clientHeight;

        this.$vue = createApp(VueApp);
        this.$vue.component('form-row', formRow);
        this.$vue.component('vwa-form', vwaForm);
        this.$vue.component('number-input', numberInput);
    }

    start() {
        this.sendContentHeight();

        this.$vue.mount('#app');
    }

    sendContentHeight() {
        const appHeight = document.getElementById('app')?.clientHeight;
        const footerHeight = document.querySelector('footer')?.clientHeight;
        const newHeight = appHeight > 0 && footerHeight > 0 ? (appHeight + footerHeight) : document.body.scrollHeight;
        if (this.lastHeight != newHeight) {
            if(window.parent) {
                window.parent.postMessage({action: 'setHeight', height: newHeight}, '*');
            }
        }
        this.lastHeight = newHeight

        setTimeout(this.sendContentHeight.bind(this), 100);
    }
}