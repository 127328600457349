<template>

<div id="fixed-progressbar" class="fixed-progressbar position-fixed w-100">

  <div class="container">

    <div class="fixed-progressbar-wrapper d-flex justify-content-between align-items-center">

      

        <a tabindex="0" class="d-block d-md-none ps-5 pt-3" type="button" data-bs-toggle="popover" data-bs-placement="bottom" aria-label="Fortschritt Krankentagegeld" id="toogleProgressBar">  
          Schritt {{ !stepControl.getCurrentStep().number ? '2' : stepControl.getCurrentStep().number}} von {{ stepControl.getVisibleSteps().length - 1 }} <i class="bi bi-chevron-down"></i>
        </a>
        
        <div id="toogleProgressBarContent" class="d-none d-md-block flex-grow-1 pe-md-5" role="toolbar" aria-label="Fortschrittsanzeige">


          <div v-if="stepControl" id="main-progress-bar-top" class="progress-bar-top main-progress-bar-top d-block d-sm-flex flex-column flex-md-row justify-content-between align-items-top py-4">
            <template v-for="(step, i) in stepControl.getVisibleSteps()">
              <template v-if="step.id == 'abfrage-gesundheit'" class="xxxxxxxxxxxxxxxxxx"></template>
              <template v-else>

                <span class="stepper-hilite" :class="step.visited ? 'done' : 'todo'">

                  <div :id="`vorschlag-progress-bar-item-${step.id}`" class="pb-3 pb-md-0 progress-bar-item progress-title me-2" :class="isSelectedStep(step) ? 'active' : ''" :data-step="step.id">
                    <span role="button" v-if="step.visited" @click.prevent="stepControl.goToStep(step.id)" class="step-number change-step me-1">{{ step.number }}</span>
                    <span v-else class="step-number me-1">{{ step.number }}</span>
                    <span class="step-title">
                      <span role="button" class="change-step" v-if="step.visited" @click.prevent="stepControl.goToStep(step.id)">{{ step.title }}</span>
                      <template v-else>{{ step.title }}</template>
                    </span>
                  </div>
                  
                </span>

              </template>
            </template>
          
          </div>
        </div>

        
        <div class="total-contribution flex-shrink-1 pt-3 pt-sm-0" v-html="totalContribution"></div>

        

        
    </div>

  </div>

</div>

</template>
<script>
export default {
  name: 'main-step-control',

  props: {
    stepControl: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },

  computed: {
    totalContribution() {
      const totalContribution = this.form?.totalContribution || 0.0;
      if(totalContribution == 0) {
        return '-,- &euro;';
      }
      const formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      });
      return formatter.format(totalContribution);
    }
  },
  methods: {
    isSelectedStep(step) {
      //step.current || step.id == 'abfrage-gesundheit'
      if(this.stepControl.getCurrentStepId() == 'abfrage-gesundheit' && step.id == 'gesundheit') {
        return true;
      }
      return step.current;
    }
  },
  mounted() {
    /* general ...
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl)
    })
    */
    
    const element = document.querySelector('#toogleProgressBar');
    if (element) {
      element.addEventListener('shown.bs.popover', () => {
        document.querySelectorAll('.progressbar-popover .change-step').forEach((item) => {
          item.addEventListener('click', () => {
            this.stepControl.goToStep(item.closest('.progress-bar-item').getAttribute('data-step'));
          });
        });
      });
      new bootstrap.Popover(element, {
          container: 'body',
          html: true,
          trigger: 'focus',
          customClass: 'progressbar-popover',
          content: function() {
            return document.querySelector('#toogleProgressBarContent').lastChild.cloneNode(true);
          },
      });
    }
  }
}
</script>