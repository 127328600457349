<template>
  <div class="form-check" :class="$attrs.class">
    <Field type="checkbox" :value="true" class="form-check-input" :name="id" :id="id" :rules="validationRules" />
    <label class="form-check-label" :for="id">
      <strong v-html="label" />
      <div class="info-text" v-if="infoText" v-html="infoText" />
    </label>
    <info-tooltip v-if="infoTooltip" :content="infoTooltip" />
  </div>
  <ErrorMessage :name="id" />
</template>
<script>
import {getUid} from "../../../utils/utils";
import { Field, ErrorMessage } from "vee-validate";
import InfoTooltip from "../../vwa-form/components/info-tooltip.vue";

export default {
  name: 'confirm-checkbox',
  components: {InfoTooltip, Field, ErrorMessage },
  props: {
    id: {
      type: String,
      required: false,
      default: () => 'confirm-checkbox-' + getUid(),
    },
    value: {
      required: false,
      default: true
    },
    required: {
      type: Boolean,
      required: false,
      default: true
    },
    label: {
      type: String,
      required: true
    },
    infoText: {
      type: String,
      required: false,
      default: null
    },
    infoTooltip: {
      type: String,
      required: false,
      default: null
    },
    invalidMessage: {
      type: String,
      required: false,
      default: 'Dieses Feld ist ein Pflichtfeld'
    }
  },
  methods: {
    validationRules(value) {
      if(!this.required) {
        return true;
      }
      if(value != this.value) {
        return this.invalidMessage;
      }
      return true;
    },
    openTooltip($event) {
      const tooltip = new bootstrap.Tooltip($event.target, {
        html: true,
        trigger: 'click',
        content: this.infoTooltip
      });
      tooltip.show();
    }
  }
}
</script>