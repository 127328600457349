<template>
  
  <vwa-jumbotron headline="Gesundheitsfragen" teaser="" />
  
  <div class="vwa-container">
    <!--<h2>Gesundheitsfragen</h2>-->
    <p>Es folgen Fragen zur Gesundheit. Sie können diese nun digital beantworten oder dies zu einem späteren Zeitpunkt im Antrag nachholen.</p>
  
    <form-check v-model="form.gesundheitsfrage" :options="gesundheitsfragenOptions" type="radio" :inline="true" />
  </div>
  
</template>
<script>
import vwaFormStepMixin from "../../../mixins/vwa-form-step.mixin";
import FormCheck from "../../form/input/form-check.vue";
import VwaJumbotron from "../components/vwa-jumbotron.vue";

export default {
  name: 'abfrage-gesundheit-step',
  components: {VwaJumbotron, FormCheck},
  mixins: [ vwaFormStepMixin ],

  computed: {
    gesundheitsfragenOptions() {
      return [
        {
          value: 'jetzt',
          label: 'Jetzt beantworten'
        },
        {
          value: 'spaeter',
          label: 'Zu einem späteren Zeitpunkt'
        }
      ]
    }
  },

  data() {
    return {

    }
  },

  created() {

  },

  methods: {
    goToNextStep() {
      if(this.form.gesundheitsfrage == 'spaeter') {
        return 'vorschlag-umsetzen';
      }
      return true;
    }
  }
}
</script>