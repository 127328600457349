<template>
  <span class="info-tooltip ms-1" data-bs-toggle="popover" data-bs-placement="auto" :data-bs-content="content">
    <i class="bi" :class="icon"></i>
  </span>
</template>
<script>
export default {
  name: 'info-tooltip',
  props: {
    content: String,
    icon: {
      type: String,
      required: false,
      default: "bi-question-circle"
    }
  },

  data() {
    return {
      popover: null
    }
  },

  mounted() {
    this.popover = new bootstrap.Popover(this.$el, {});
  },

  unmounted() {
    this.popover.dispose();
  }
}
</script>