<template>
  <vwa-jumbotron headline="Vorversicherungsangaben" teaser="" />
  <div class="vwa-container">
    <div class="versicherung">
      <label class="form-label mb-3">Bitte benennen Sie Ihre Krankenversicherung bei der Sie derzeit versichert sind.</label>
      <div class="d-block">
        <label class="form-label">Name des Versicherers:</label>
        <form-field v-model="form.versicherung.name" :required="true" />
        <form-check :options="versicherungOptions" v-model="form.versicherung.typ" :required="true" :inline="true" type="radio" />
      </div>
    </div>
    <div class="krankengeld">
      <label class="form-label">Bestehendes Kranken(tage)geld?</label>
      <form-check :options="krankengeldOptions" v-model="form.versicherung.krankengeld" :required="true" :inline="true" type="radio">
        <template #option-suffix-ja>
          <div v-if="form.versicherung.krankengeld == 'ja'" class="d-inline">, Höhe: <form-field v-model="form.versicherung.krankengeldHoehe" class="form-field-inline" :required="true" suffix="&euro;" type="number" /></div>
        </template>
      </form-check>
    </div>
  </div>
</template>
<script>
import vwaFormStepMixin from "../../../mixins/vwa-form-step.mixin";
import { Field, ErrorMessage } from "vee-validate";
import formValidationMixin from "../../../mixins/form-validation.mixin";
import FormCheck from "../../form/input/form-check.vue";
import FormField from "../../form/form-field.vue";
import VwaJumbotron from "../components/vwa-jumbotron.vue";

export default {
  name: 'versicherung-step',
  mixins: [ vwaFormStepMixin, formValidationMixin ],
  components: {VwaJumbotron, FormField, FormCheck, Field, ErrorMessage },

  computed: {
    versicherungOptions() {
      return [
        {
          value: 'privat',
          label: 'privat'
        },
        {
          value: 'gesetzlich',
          label: 'gesetzlich'
        }
      ];
    },
    krankengeldOptions() {
      return [
        {
          value: 'ja',
          label: 'Ja'
        },
        {
          value: 'nein',
          label: 'Nein'
        }
      ];
    }
  },

  created() {
    if(typeof this.form.versicherung === 'undefined') {
      this.form.versicherung = {};
    }
  }
}
</script>