<template>
  
    <div class="servicemodul-wrapper vwa-container my-4">
      <h3 class="h5 text-center" v-if="!services[service.id].default">{{ service.title }}:</h3>
      <h3 class="h5 text-center" v-if="services[service.id].default">Ihr Leistungsbaustein:</h3>
        
      <div class="servicemodul p-3 mt-3" :class="[showEditContent ? 'show' :'' , services[service.id].selected ? 'selected' : '', !services[service.id].selected ? 'selectable' : '']">


        <div class="modul-content" v-if="showEditContent">
          <div class="mt-2">
            <label>Leistungsbeginn ab Tag:</label>
            <start-day-select v-model="services[service.id].startDay" :disabled-start-days="disabledStartDays" :start-days="startDays" @update:modelValue="revalidate" />
          </div>
          <div class="mt-4">
            <label class="mb-3">Gewünschtes Krankentagegeld (KTG):</label>
            <sick-pay-select v-model="services[service.id].sickPay" :max-sick-pay="calculatedMaxSickPay" @change="revalidate" />
          </div>

          <div class="button-group">
            <div class="d-flex flex-row-reverse gap-2">
              <button class="btn btn-primary btn-sm" v-if="!autoCalculate" @click.prevent="closeService">speichern</button>
              <button class="btn btn-primary btn-sm" v-if="!services[service.id].default && !services[service.id].selected" @click.prevent="services[service.id].show = false">abbrechen</button>
            </div>
          </div>
          <ErrorMessage :name="id" />
        </div>

        <div v-else class="d-block d-sm-flex flex-wrapp justify-content-between">

        

          <template v-if="services[service.id].selected">

            <div class="d-flex  align-items-center">

              <img :src="require(`../../../../assets/images/${service.image}`)" :alt="service.title" width="50" height="50" class="d-none d-sm-block">

              <div class="servicemodul-info px-3">
                Leistungbeginn ab Tag: {{ services[service.id].startDay }}<br>
                Ihr Krankentagegeld: {{ services[service.id].sickPay }} &euro;<br/>
                Beitrag: <span v-html="formatCurrency(services[service.id].contribution)"></span>
              </div>

            </div>
            <div class="servicemodul-btns pt-2 pt-sm-0" :class="showRemoveBtn ? 'd-flex d-sm-block justify-content-around' : 'd-block text-center d-sm-flex flex-column justify-content-center'">
              
              <button class="btn btn-primary btn-sm d-block" :disabled="otherServiceShow" @click.prevent="editService">ändern</button>
              <button class="btn btn-primary btn-sm mt-sm-3 d-block" v-if="showRemoveBtn" @click.prevent="removeService">löschen</button>
              
            </div>
          </template>
          <template v-else>
            <div class="d-flex align-items-center">

              <img :src="require(`../../../../assets/images/${service.image}`)" :alt="service.title" width="50" height="50" class="d-none d-sm-block">
              
              <div class="servicemodul-info px-3">
                Hier können Sie auswählen, ob die von Ihnen gewählte Krankentagegeldabsicherung im Zeitverlauf aufgestockt werden soll.
              </div>
            </div>
            <div class="servicemodul-btns pt-2 pt-sm-0 d-block text-center d-sm-flex flex-column justify-content-center">
              <button v-if="showCreateServiceBtn && calculatedMaxSickPay > 0 && !isMaxStartDayDisabled()" class="btn btn-primary btn-sm d-block" @click.prevent="services[service.id].show = true">auswählen</button>
            </div>
          </template>
        
        </div>
      </div>
    </div>
    
  
</template>
<script>
import StartDaySelect from "./start-day-select.vue";
import SickPaySelect from "./sick-pay-select.vue";
import { ErrorMessage, useField } from "vee-validate";
import {formatCurrency, getUid, isEmpty} from "../../../utils/utils";

export default {
  name: 'servicemodul',
  components: { ErrorMessage, SickPaySelect, StartDaySelect },
  emits: [ 'recalculate' ],
  props: {
    id: {
      type: String,
      required: false,
      default: () => 'servicemodul-' + getUid(),
    },
    service: {
      type: Object,
      required: true
    },
    services: {
      type: Object,
      required: true
    },
    erasable: {
      type: Boolean,
      required: false,
      default: true
    },
    autoCalculate: {
      type: Boolean,
      required: false,
      default: false
    },
    startDays: {
      type: Array,
      required: false,
      default: [ 4, 8, 15, 22, 29, 43, 92, 183 ]
    },
    startDaysPerRow: {
      type: Number,
      required: false,
      default: 4
    },
    minSickPay: {
      type: Number,
      required: false,
      default: 5
    },
    maxSickPay: {
      type: Number,
      required: false,
      default: 520
    },
    sickPaySteps: {
      type: Number,
      required: false,
      default: 5
    },
    maxTotalSickPay: {
      type: Number,
      required: false,
      default: 520
    }
  },

  computed: {
    otherServiceShow() {
      for(let serviceId in this.services) {
        if (serviceId == this.service.id) {
          continue;
        }
        const service = this.services[serviceId];
        if(service.show) {
          return true;
        }
      }
      return false;
    },
    isSelected() {
      return this.services[this.service.id]?.selected === true;
    },
    showCreateServiceBtn() {
      if(this.otherServiceShow) {
        return false;
      }
      if(this.isSelected) {
        return false;
      }
      if(this.service.id > 0 && typeof this.services[this.service.id - 1] !== 'undefined') {
        return this.services[this.service.id - 1].selected;
      }
      return false;
    },
    showEditContent() {
      return this.services[this.service.id].show || this.autoCalculate;
    },
    showRemoveBtn() {
      if(this.otherServiceShow || this.service.default) {
        return false;
      }
      if(this.services[this.service.id].selected && this.service.id == Object.values(this.services).length - 1) {
        return true;
      }
      if(this.service.id > 0 && typeof this.services[this.service.id + 1] !== 'undefined') {
        return !this.services[this.service.id + 1].selected;
      }
      return false;
    },
    maxStartDay() {
      let maxStartDay = 0;
      for(let serviceId in this.services) {
        const service = this.services[serviceId];
        if(!service?.selected && !service?.default) {
          continue;
        }
        if(parseInt(service.startDay) > maxStartDay) {
          maxStartDay = parseInt(service.startDay);
        }
      }
      return maxStartDay;
    },
    disabledStartDays() {
      const disabledStartDays = [];
      let minStartDay = 0;
      let maxStartDay = 0;

      if(typeof this.services[this.service.id - 1] !== 'undefined' && this.services[this.service.id - 1].selected) {
        minStartDay = parseInt(this.services[this.service.id - 1].startDay);
      }
      if(typeof this.services[this.service.id + 1] !== 'undefined' && this.services[this.service.id + 1].selected) {
        maxStartDay = parseInt(this.services[this.service.id + 1].startDay);
      }

      for(let d in this.startDays) {
        const day = this.startDays[d];
        if(minStartDay > 0 && day <= minStartDay || maxStartDay > 0 && day >= maxStartDay) {
          disabledStartDays.push(day.toString());
        }
      }

      return disabledStartDays;
    },
    calculatedMaxSickPay() {
      let totalSickPay = 0;
      for(let serviceId in this.services) {
        if(serviceId == this.service.id) {
          continue;
        }
        const service = this.services[serviceId];
        if(!service?.selected) {
          continue;
        }
        totalSickPay+= service.sickPay;
      }
      return this.maxSickPay - totalSickPay;
    }
  },

  data() {
    return {
      validation: null
    }
  },

  /*setup(props, { emit, attrs }) {

  },*/

  created() {
    if(typeof this.services[this.service.id].sickPay === "undefined") {
      this.services[this.service.id].sickPay = 5;
    }

    const onValidate = () => {
      const service = this.services[this.service.id] || null;
      if(!service?.selected && !service?.show && !service?.default) {
        return true;
      }
      if(isEmpty(service?.startDay)) {
        return 'Bitte wählen Sie den Tag ab dem Sie die Leistung beziehen möchten';
      }
      return true;
    };

    this.validation = useField(this.id, onValidate, {
      initialValue: this.services[this.service.id],
      valueProp: this.services[this.service.id]
    });
  },

  methods: {
    formatCurrency,

    closeService() {
      this.validation.validate().then((res) => {
        if(!res.valid) {
          return;
        }
        this.services[this.service.id].selected = true;
        this.services[this.service.id].show = false;
        this.$emit('recalculate', { serviceId: this.service.id });
      });
    },
    getMaxSickPay(service) {

    },
    editService() {
      this.services[this.service.id].show = true
    },
    removeService() {
      this.services[this.service.id].selected = false;
      this.services[this.service.id].show = false;
      this.services[this.service.id].startDay = null;
      this.$emit('recalculate', { serviceId: this.service.id });
    },
    revalidate() {
      this.validation.validate().then((res) => {
        if(this.autoCalculate && res.valid) {
          this.$emit('recalculate', { serviceId: this.service.id });
        }
      });
    },
    isMaxStartDayDisabled() {
      for(let s in this.services) {
        const service = this.services[s];
        if((service.selected || service.default) && service.startDay && service.startDay == this.startDays[this.startDays.length - 1]) {
          return true;
        }
      }
      return false;
    }
  }
}
</script>