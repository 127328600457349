<template>
  <div class="vwa-price-info" v-if="form.infoText && form?.totalContribution > 0">

    <div class="vwa-container">
      <div class="row">
        <div class="col-12">

          <div class="zahlbetrag-box my-3">

            <div class="d-flex justify-content-between">
              <strong>Ihr aktueller Zahlbeitrag</strong>
              <div class="ms-auto total-contribution">
                <div class="price-value text-end fw-bold" v-html="totalContribution"></div>
                <span class="price-interval"><small>monatlich</small></span>
              </div>
            </div>
            <div class="hint-text px-0 py-4 px-md-4" v-if="form.infoText" v-html="form.infoText"></div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {formatCurrency} from "../../../utils/utils";

export default {
  name: 'vwa-price-info',

  props: {
    form: {
      type: Object,
      required: true
    }
  },

  computed: {
    totalContribution() {
      const totalContribution = this.form?.totalContribution || 0.0;
      return formatCurrency(totalContribution);
    }
  }
}
</script>