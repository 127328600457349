  <template>
    <div class="vwa-container d-none d-sm-block">

      <div class="service-charts mt-5">
    
        <div :class="`d-flex align-items-center gap-2 mb-1 service-chart service-chart-${service.id}`" v-for="service in infoGrafik">
          <div class="service-name">{{ service.name }}</div>
          <div class="chart-bar w-100 text-end d-flex justify-content-end">
            <!--
            <div class="chart-bar-dummy" :style="`width: ${420 - service.width}px`"></div>
            -->
            
            <div :class="`chart-bar-content text-end chart-bar-color-${service.id}`" :style="`width: ${service.width}%;`">Ab Tag {{ service.startDay }}, {{ formatCurrency(service.sickPay) }} / Tag = {{ formatCurrency(service.coverage) }} / Monat</div>
            
            
          </div>
        </div>

      </div>
</div>
</template>
<script>
import {formatCurrency} from "../../../utils/utils";
import vwaFormStepMixin from "../../../mixins/vwa-form-step.mixin";

export default {
  name: 'vwa-service-chart',
  mixins: [ vwaFormStepMixin ],

  props: {
    form: {
      type: Object,
      required: true
    }
  },

  computed: {
    sortedSelectedModules() {
      return Object.values(this.form.services)
          .filter((module) => module.selected)
          .sort((left, right) => (left.startDay || 0) - (right.startDay || 0));
    },

    infoGrafik() {
      if(this.sortedSelectedModules.length === 0) {
        return null;
      }

      const grafikParts = [];
      const lastStartDay = parseInt(this.startDays[this.startDays.length - 1]);

      for(let i = 0; i < this.sortedSelectedModules.length; i++) {
        const module = this.sortedSelectedModules[i];
        const startDay = parseInt(module.startDay);
        const widthPercent = 100 - Math.round(startDay * 100 / lastStartDay);
        const width = 320;
        
        grafikParts.push({
          id: i,
          name: i==0 ? 'Leistungsbaustein ' + (i + 1).toString() : 'Zusatzbaustein ' + (i + 1).toString(),
          startDay: module.startDay,
          sickPay: module.sickPay,
          totalSickPay: module.totalSickPay,
          contribution: module.contribution,
          totalContribution: module.totalContribution,
          coverage: module.coverage,
          totalCoverage: module.totalCoverage,
          widthPercent,
          /*width: width + (widthPercent * 100 / 100)*/
          width: 100 - i * 10
        });
      }
      return grafikParts.reverse();
    },
  },

  methods: {
    formatCurrency
  }
}
</script>