export default {
    methods: {
        isValidEmail(email) {
            return email.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
        },
        isRequiredField(value) {
            if(value === null || value === undefined || value === '') {
                return 'Dieses Feld ist ein Pflichtfeld.';
            }
            return true;
        },
        isRequiredEmailField(value) {
            if(value === null || value === undefined || value === '') {
                return 'Dieses Feld ist ein Pflichtfeld.';
            }
            if(!this.isValidEmail(value)) {
                return 'Bitte geben Sie eine gültige E-Mail ein';
            }
            return true;
        },
        isRequiredCheckbox(value) {
            if(!value) {
                return "Dieses Feld ist ein Pflichtfeld.";
            }
            return true;
        },
    }
}