<template>
  <div class="vwa-container vwa-jumbotron position-relative pp-4 text-center bbg-vwa" :class="class">
    <span v-if="teaser" class="fs-5 text-muted" v-html="teaser" />
    <h1 class="text-body-emphasis" v-html="headline" />
  </div>
</template>
<script>
export default {
  name: 'vwa-jumbotron',
  props: {
    headline: {
      type: String,
      required: true
    },
    teaser: {
      type: [ String, null ],
      required: false,
      default: 'Ihr Krankentagegeld'
    }
  },

  computed: {
    class() {
      return this.$props.class || 'mb-4'
    }
  }
}
</script>