<template>
  <vwa-jumbotron headline="Vorschlag umsetzen" teaser=""/>

  <div class="vwa-container">

    <div class="section vermittler-section">
      <h3 class="my-3">Vermittler</h3>
      <p>Sie entscheiden über welchen Vermittler Ihr Antrag eingereicht werden soll.</p>
      <form-check :options="vermittlerOptions" v-model="form.vermittler.typ" :required="true" type="radio" />
      <div class="vermittler" v-if="form.vermittler.typ == 'eigener'">
        <form-field v-model="form.vermittler.name" label="Name des Vermittlers" :required="true" />
        <form-field v-model="form.vermittler.number" label="Vermittlernummer bei DKV" :required="false" />
        <form-field v-model="form.vermittler.email" label="E-Mail Adresse des Vermittlers" :required="true" />
        <form-field v-model="form.vermittler.telefon" label="Telefonnummer des Vermittlers" :required="false" />
      </div>
    </div>

    <div class="section versicherungsbeginn-section bg-vwa p-3">
      <h3 class="my-3">Versicherungsbeginn</h3>
      <div class="d-block d-sm-flex gap-2 align-items-center mb-3">
        <div>Am</div>
        <div>
          <select-field class="pb-0" :options="versicherungBeginnOptions" v-model="form.versicherung.beginn" />
        </div>
        <div>soll die Versicherung starten.</div>
      </div>
    </div>

    <div class="section persoenliche-angaben-section">
      <h3 class="my-3">Persönliche Angaben</h3>
      <div class="d-flex gap-2 w-100">
        <select-field v-model="form.person.anrede" :options="anredeOptions" label="Anrede" :required="true" />
        <select-field v-model="form.person.titel" :options="titelOptions" label="Titel" />
      </div>
      <div class="d-flex gap-2">
        <form-field v-model="form.person.vorname" label="Vorname" :required="true" />
        <form-field class="flex-grow-1" v-model="form.person.nachname" label="Nachname" :required="true" />
      </div> 
      <div class="form-group d-block d-sm-flex gap-2">
        <form-field type="email" v-model="form.person.email" label="E-Mail" :required="true" />
        <form-field v-model="form.person.telefon" label="Telefon" :required="true" />
      </div> 
      <div class="form-group d-flex gap-2">
        <form-field class="flex-grow-1" v-model="form.person.strasse" label="Straße" :required="false" />
        <form-field v-model="form.person.hausnummer" label="Hausnummer" :required="false" />
      </div>
      <div class="form-group d-flex gap-2">
        <form-field v-model="form.person.plz" label="PLZ" :required="false" />
        <form-field class="flex-grow-1" v-model="form.person.ort" label="Ort" :required="false" />
      </div>
      <!--
      <form-field v-model="form.person.natonalitaet" label="Nationalität" :required="false" />
      <form-field v-model="form.person.facharzt" label="Facharzt" :required="false" />
      -->
      <form-field v-model="form.person.taetigkeit" label="ausgeübter Beruf (genaue Bezeichnung)" :required="false" />

      <template v-if="form.taetigkeit == 'arbeitnehmer'">
        <form-field v-model="form.person.einkommen" label="monatliches Brutoeinkommen" :required="false" suffix="&euro;" type="number" />
        <form-check v-model="form.person.entgeletortzahlung" :options="yesNoRadioOptions" label="Haben Sie mit Ihrem Arbeitgeber eine längere Entgeltortzahlung im Krankheitsfall vereinbart als die gesetzlich vorgeschriebene (6 Wochen)?" :inline="false" type="radio">
          <template #option-suffix-ja>
            <div v-if="form.person.entgeletortzahlung == 'ja'" class="d-block">
              <form-field v-model="form.person.entgeletortzahlungTage" type="number" class="irgendeineklasse" :required="false" suffix="Tage" />
            </div>
          </template>
        </form-check>
      </template>
      <template v-if="form.taetigkeit == 'freiberufler'">
        <form-field v-model="form.person.einkommen" label="monatliches Nettoeinkommen" :required="true" suffix="&euro;" type="number" />
        <form-field v-model="form.person.praxisFixkosten" label="Praxisbezogene Fixkosten pro Jahr" :required="false" suffix="&euro;" type="number" />
        <form-field type="date" v-model="form.person.beginnTaetigkeit" label="Beginn der freiberuflichen Tätigkeit." :required="false" />
      </template>

    </div>

    <div class="section kontodaten-section">
      <h2>Kontodaten</h2>
      <p>Ihre Kontodaten werden an den Versicherer übermitelt. Eine Abbuchung erfolgt nur bei Zustandekommen des Vertrages.</p>
      <p>Zahlungsart per SEPA-Lastschrift</p>
      <form-field v-model="form.konto.iban" label="IBAN" prefix="DE" :required="false" />
    </div>

    <div class="section schweigepflichtentbindungserklaerung-section">
      <h2>Einwilligung zur Datenverarbeitung</h2>
      <p>
        Bitte lesen Sie die Erklärung zur Datenverarbeitung:<br/>
        <a href="https://www.vwaev.de/wp-content/uploads/2023/11/Datenschutzerklaerung-vwa-ev.pdf" target="_blank">
          <i class="bi bi-file-pdf"></i>
          Erklärung zur Datenverarbeitung
        </a>
      </p>
      <confirm-checkbox label="Ich habe die Erklärung zur Datenverarbeitung gelesen und stimme ihr zu." />
     </div>

    <div class="section beratung-section">
      <h2>Beratung</h2>
      <p>
        Hinweis: Der Verband Wirtschaft & Arzt e.V. (VWA e.V.) ist selbst kein Versicherungsvermittler und präsentiert auf seiner Webseite seine mit der DKV geschlossenen Verbandskonditionen. Sie als Interessent/in, erhalten auf Basis der von Ihnen gemachten Angaben, unterschriftsreife Antragsunterlagen, aufbereitet. Diese sind im Nachgang von Ihnen zu unterschreiben und über einen Vermittler (Wahlweise Kooperationspartner des VWA, alternativ ein Ihnen bekannter Vermittler mit Anbindung an die DKV) bei der DKV einzureichen.
      </p>
      <p>Sofern mit dem gewählten Vermittler nicht anders besprochen, findet weder eine Beratung noch eine Dokumentation der Beratung statt.</p>
      <confirm-checkbox label="Ich verzichte hiermit auf Beratung und Dokumentation der Beratung." />
    </div>

    <div v-if="form.gesundheitsfrage == 'spaeter'" class="section erinnerung-gesundheitsfragen-section">
      <p>Wollen Sie nun die Gesundheitsfragen digital beantworten? Ansonsten müssen diese später im ausgedruckten Antrag schriftlich ausgefüllt werden.</p>
      <button role="button" class="btn btn-secondary" @click.prevent="form.gesundheitsfrage = 'jetzt'; $emit('go-to-step', 'gesundheit')">Gesundheitsfragen beantworten</button>
    </div>

    <div class="section antrag-section">
      <h2>Antrag</h2>
      <p>Mit Klick auf Vorschlag umsetzen werden die von Ihnen eingetragenen Informationen von uns in einem PDF für Sie unterschriftsreif aufbereitet. Dieses PDF erhalten Sie per E-Mail. Ebenso erhält der von Ihnen gewählte Vermittler, zwecks Einreichung beim Versicherer, diesen Antrag und wird automatisch auf Sie zukommen.</p>
    </div>
  </div>
</template>
<script>
import FormField from "../../form/form-field.vue";
import { Field } from "vee-validate";
import vwaFormStepMixin from "../../../mixins/vwa-form-step.mixin";
import SelectField from "../../form/input/select-field.vue";
import formValidationMixin from "../../../mixins/form-validation.mixin";
import ConfirmCheckbox from "../../form/input/confirm-checkbox.vue";
import FormCheck from "../../form/input/form-check.vue";
import { isHoliday } from 'feiertagejs';
import {isEmpty} from "../../../utils/utils";
import VwaJumbotron from "../components/vwa-jumbotron.vue";

export default {
  name: 'vorschlag-umsetzen-step',
  components: {VwaJumbotron, FormCheck, ConfirmCheckbox, SelectField, FormField, Field },
  mixins: [ vwaFormStepMixin, formValidationMixin ],

  computed: {
    anredeOptions() {
      return [
        {
          value: '',
          label: 'Wählen Sie eine Anrede aus'
        },
        {
          value: 'weiblich',
          label: 'Frau'
        },
        {
          value: 'männlich',
          label: 'Herr'
        }
      ];
    },
    titelOptions() {
      return [
        {
          value: '',
          label: 'Ohne Titel'
        },
        {
          value: 'Dr.',
          label: 'Dr.'
        },
        {
          value: 'Dr. med.',
          label: 'Dr. med.'
        },
        {
          value: 'Dr. med. dent.',
          label: 'Dr. med. dent.'
        },
        {
          value: 'PD Dr. med.',
          label: 'PD Dr. med.'
        },
        {
          value: 'Dr. Dr.',
          label: 'Dr. Dr.'
        },
        {
          value: 'Dipl.-Med.',
          label: 'Dipl.-Med.'
        },
        {
          value: 'Dipl.-Psych.',
          label: 'Dipl.-Psych.'
        },
        {
          value: 'Prof.',
          label: 'Prof.'
        },
        {
          value: 'Prof. Dr.',
          label: 'Prof. Dr.'
        },
        {
          value: 'Prof. Dr. Dr.',
          label: 'Prof. Dr. Dr.'
        },
        {
          value: 'Dipl.-Psych. Dr. phi',
          label: 'Dipl.-Psych. Dr. phi'
        },
        {
          value: 'Dipl.-Stom.',
          label: 'Dipl.-Stom.'
        },
        {
          value: 'SR Dr.',
          label: 'SR Dr.'
        },
        {
          value: 'Dipl. Sport. Univ.',
          label: 'Dipl. Sport. Univ.'
        },
        {
          value: 'PD Dr. Dr. med. habi',
          label: 'PD Dr. Dr. med. habi'
        },
        {
          value: 'Priv. Doz. Dr.',
          label: 'Priv. Doz. Dr.'
        },
        {
          value: 'Freiin',
          label: 'Freiin'
        },
        {
          value: 'Freiin Dipl.-Med.',
          label: 'Freiin Dipl.-Med.'
        },
        {
          value: 'Freiin Dr.',
          label: 'Freiin Dr.'
        },
        {
          value: 'Medizinalrat',
          label: 'Medizinalrat'
        }
      ]
    },
    showErinnerungGesundheitsfragen() {

    },
    vermittlerOptions() {
      return [
        {
          value: 'vwa',
          label: 'Kooperationspartner des VWA e.V.',
          infoTooltip: 'Ihre Anfrage wird direkt an unseren Versicherungspartner (VWA GmbH) weitergeleitet. Dieser übernimmt die weitere Betreuung und Abwicklung Ihres Antrages und steht Ihnen auch im Leistungsfall zur Seite.'
        },
        {
          value: 'eigener',
          label: 'Eigenen Vermittler angeben',
          infoTooltip: 'Sofern Sie bereits einen Vermittler mit Anbindung an die DKV haben, können Sie diesen hier benennen. Ihr Vermittler wird dann von uns informiert, so dass er Sie kontaktieren und den Abschluss vornehmen kann.'
        }
      ];
    },
    versicherungBeginnOptions() {
      const options = [];
      const date = new Date();
      const day = date.getDate();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let maxMonth = 3;
      let minWorkingDays = 3;
      let daysOfMonth = new Date(year, month, 0).getDate();
      let skipFirstMonth = false;

      for(let d = daysOfMonth; d >= 1; d--) {
        const currDate = new Date(year, date.getMonth(), d, date.getHours(), date.getMinutes(), date.getSeconds());
        if(d < day) {
          break;
        }

        if(!isHoliday(currDate, 'NW') && currDate.getDay() !== 0 && currDate.getDay() !== 6) {
          minWorkingDays--;
        }
      }

      skipFirstMonth = minWorkingDays > 0;
      if(skipFirstMonth) {
        month++;
      }

      for(let m = 1; m <= maxMonth; m++) {
        if(month + 1 > 12) {
          month = 1;
          year++;
        } else {
          month++;
        }

        options.push({
          value: year + '-' + ('00' + month).slice(-2) + '-01',
          label: '01.' + ('00' + month).slice(-2) + '.' + year
        });
      }

      if(!this.form.versicherung?.beginn && options.length > 0) {
        this.form.versicherung.beginn = options[0].value;
      } else if(this.form.versicherung?.beginn && options.filter((option) => option.value == this.form.versicherung?.beginn).length === 0 && options.length > 0) {
        this.form.versicherung.beginn = options[0].value;
      }

      return options;
    },
    beitragszahlungOptions() {
      return [
        {
          value: 'monatlich',
          label: 'monatlich'
        },
        {
          value: 'vierteljaehrlich',
          label: 'vierteljährlich'
        },
        {
          value: 'halbjaehrlich',
          label: 'halbjährlich'
        },
        {
          value: 'jaehrlich',
          label: 'jährlich'
        }
      ];
    }
  },

  created() {
    if(typeof this.form.vermittler === 'undefined') {
      this.form.vermittler = {};
    }
    if(typeof this.form.person === 'undefined') {
      this.form.person = {};
    }
    if(typeof this.form.konto === 'undefined') {
      this.form.konto = {};
    }
    if(typeof this.form.versicherung === 'undefined') {
      this.form.versicherung = {};
    }
    if(isEmpty(this.form.konto.beitragszahlung)) {
      this.form.konto.beitragszahlung = 'monatlich'
    }
    if(isEmpty(this.form.versicherung.beginn)) {
      this.form.versicherung.beginn = '2023-09-01'
    }
  }

}
</script>