import axios from "axios";
import config from "../../../config/config.inc";

export default class ApiClient {
    getFormConfiguration(formId) {
        return this.getRequest(`vwa-gruppe/${formId}/configuration`);
    }

    calculateServices(formId, age, customerType, services) {
        const selectedServices = Object.values(services)
            .filter((service) => service.selected || service.default)
            .map((service) => {
                return { id: service.id, startDay: service.startDay, sickPay: service.sickPay };
            });
        return this.postRequest(`vwa-gruppe/${formId}/calculate`, { age, customerType, services: selectedServices });
    }

    submitForm(formId, form) {
        if(typeof form.gesundheitsfragen.Details_G11 !== 'undefined') {
            form.gesundheitsfragen.Details_G11 = Object.values(form.gesundheitsfragen.Details_G11)
                .filter((gesundheitsfrage) => gesundheitsfrage.show || false);
        }
        return this.postRequest(`form/${formId}`, form);
    }

    getRequest(endpoint, query = {}, options = {}) {
        return new Promise((resolve, reject) => {
            const client = this._createClient();
            options.params = query;
            return client.get(this._getApiActionUrl(endpoint), options)
                .then(resolve)
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    }

    postRequest(endpoint, data = {}) {
        return new Promise((resolve, reject) => {
            const client = this._createClient();
            return client.post(this._getApiActionUrl(endpoint), data)
                .then(resolve)
                .catch((error) => {
                    console.error(error);
                    reject(error);
                })
        });
    }

    _createClient() {
        const headers = {
            'Authorization': 'Bearer ' + config.apiKey,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        const client = axios.create({
            headers: headers
        });
        client.interceptors.response.use(response => response, error => {
            console.log('errors', { error });
            const { response: { status, data: { errors, data } } } = error;
            return Promise.reject({ errors: errors || {}, error });
        });
        return client;
    }

    _getApiActionUrl(endpoint) {
        return config.apiUrl + '/api/' + endpoint;
    }
}