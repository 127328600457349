<template>
  <div class="sick-pay-select d-flex gap-5 flex-wrap flex-md-nowrap mb-3">
    <input type="range" class="form-range" :min="minSickPay" :max="maxSickPay" :step="sickPaySteps" :value="currentValue" @input="onValueChanged">
    <div class="input-group">
      <span class="input-group-text" id="sick-pay-prefix">&euro;</span>
      <input type="number" class="form-control sick-pay-input" ref="numberInput" :min="minSickPay" :max="maxSickPay" :step="sickPaySteps" :value="currentValue" @change="onValueChanged" aria-describedby="sick-pay-prefix">
    </div>
  </div>
</template>
<script>
export default {
  name: 'sick-pay-select',
  emits: [ 'update:modelValue' ],
  props: {
    modelValue: Number,
    minSickPay: {
      type: Number,
      required: false,
      default: 5
    },
    maxSickPay: {
      type: Number,
      required: false,
      default: 520
    },
    sickPaySteps: {
      type: Number,
      required: false,
      default: 5
    },
    totalSickPay: {
      type: Number,
      required: false,
      default: 0
    }
  },

  computed: {
    currentValue() {
      return this.modelValue || this.minSickPay;
    }
  },

  methods: {
    onValueChanged($event) {
      $event.preventDefault();
      const value = parseInt($event.target.value);
      if(value < this.minSickPay || value > this.maxSickPay || value % this.sickPaySteps !== 0) {
        this.$refs.numberInput.value = this.currentValue;
        return;
      }
      if(value) {
        this.$emit('update:modelValue', value);
      }
    }
  }
}
</script>