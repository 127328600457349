export default {
    emits: [ 'go-to-step', 'update-prev-step-disabled' ],

    props: {
        stepControl: {
            type: Object,
            required: true
        },
        step: {
            type: Object,
            required: true
        },
        form: {
            type: Object,
            required: true
        },
        formId: {
            type: String,
            required: false,
            default: null
        },
        services: {
            type: Array,
            required: false,
            default: []
        }
    },

    computed: {
        yesNoRadioOptions() {
            return [
                {
                    value: 'ja',
                    label: 'Ja'
                },
                {
                    value: 'nein',
                    label: 'Nein'
                }
            ];
        },
        startDays() {
            if(this.form.customerType == 'KTAA') {
                return [ '4', '8', '15', '22', '29', '31', '43', '92', '183' ];
            }
            return [ '43', '64', '85', '92', '106', '127', '169', '183', '274', '365' ];
        },
    }
}