<template>



  <template v-if="!isLoading">

      <div class="success-page" v-if="isComplete">
        <div class="vwa-container mb-5">
          <div class="row">
            <div class="col-12">
              <h2 class="text-center mb-5">Herzlichen Glückwunsch!</h2>
              <div class="text-center">
                
                <p>Ihr Antrag wurde versendet.</p>
                
                <p>Ihre Informationen werden von uns unterschriftsreif aufbereitet und anschließend erhalten Sie Ihren Antrag per E-Mail.</p>
              </div>
            </div>
          </div>
        </div>
      </div>          
    <template v-else>

      <span class="x---------------------------------------------x" :class="`step ${displayStep.id}-step`"></span>

            <main-step-control :step-control="stepControl" :form="form" />
            <vorschlag-step-control v-if="currentSubStepControl && currentSubStepControl.id == 'vorschlag'" :step-control="currentSubStepControl" />


      <Form ref="form" class="needs-validation">

        <div :class="`step ${displayStep.id}-step`">
          <component v-if="displayStep.component" ref="currentComponent" :stepControl="stepControl" :step="displayStep" :services="services" :form-id="formId" :form="form" :is="displayStep.component" @go-to-step="goToStep" @update-prev-step-disabled="onUpdatePrevStepDisabled" />
        </div>

      </Form>

      <div class="form-footer">
        <template v-if="displayStep.number && displayStep.number < 2">
          <vwa-price-info :form="form" />
        </template>
        <div class="container">
          <div class="row">
            <div class="col-12">


              <div class="form-step-navigator d-flex flex-row justify-content-center align-items-center">
                
                <button class="btn btn-secondary" role="button" v-if="displayStep.id == 'gesundheit'" @click.prevent="form.gesundheitsfrage = 'spaeter'; goToStep('vorschlag-umsetzen')">Mit bisherigen Gesundheitsfragen<span class="d-block d-sm-none"></span>den Vorschlag umsetzen</button>

                
                <button class="btn btn-primary step-next" role="button" v-if="displayStep.id !== 'vorschlag-umsetzen'" @click.prevent="goToNextStep" :disabled="nextBtnDisabled">weiter</button>
                
                <button class="btn btn-primary step-next btn-lg" role="button" v-if="displayStep.id === 'vorschlag-umsetzen'" @click.prevent="submitForm" :disabled="isProcessing">
                    <div class="spinner-border me-2" role="status" v-if="isProcessing">
                      <span class="sr-only"></span>
                    </div>
                    <span class="align-middle">Vorschlag umsetzen</span>
                </button>
                

                <button class="btn btn-secondary step-prev" role="button" @click.prevent="goToPrevStep" :disabled="prevBtnDisabled">zurück</button>

              </div>

              

            </div>
          </div>
        </div>

      </div>

      <template v-if="displayStep.id == 'vorschlag' && showDemoDatenBtn">
        
        <a class="position-fixed" style="z-index:1112;color:white;" href="#" @click.prevent="loadDemoData">Demodaten laden</a>
      </template>
    </template>
  </template>

</template>
<script>
import { Form } from "vee-validate";
import { markRaw } from "vue";
import config from "../../../../config/config.inc";
import vorschlagStep from "./steps/vorschlag-step.vue";
import abfrageGesundheitStep from "./steps/abfrage-gesundheit-step.vue";
import gesundheitStep from "./steps/gesundheit-step.vue";
import vorschlagUmsetzenStep from "./steps/vorschlag-umsetzen-step.vue";
import StepControl from "./step-control";
import MainStepControl from "./step-control/main-step-control.vue";
import VorschlagStepControl from "./step-control/vorschlag-step-control.vue";
import ApiClient from "../../utils/api-client";
import versicherungStep from "./steps/versicherung-step.vue";
import VwaPriceInfo from "./components/vwa-price-info.vue";

export default {
  name: 'vwa-form',
  components: {VwaPriceInfo, VorschlagStepControl, MainStepControl, Form },

  computed: {
    currentStep() {
      return this.stepControl.getCurrentStep();
    },
    currentSubStep() {
      return this.currentSubStepControl?.getCurrentStep() || null;
    },
    displayStep() {
      return this.currentSubStep || this.currentStep;
    },
    currentSubStepControl() {
      const currentStepKey = this.stepControl.getCurrentStepId();
      return this.subStepsControls[currentStepKey] || null;
    },
    prevBtnDisabled() {
      if(this.$refs.currentComponent && typeof this.$refs.currentComponent.prevBtnDisabled == 'function') {
        return this.$refs.currentComponent.prevBtnDisabled();
      }
      return this.stepControl.stepHistory.length === 0 && this.isPrevStepDisabled;
    },
    nextBtnDisabled() {
      const currentStep = this.stepControl.getCurrentStep();
      return currentStep?.lastStep;
    },
    showDemoDatenBtn() {
      return false;
    }
  },

  data() {
    return {
      pdfLink: require("../../../assets/Datenschutzerklaerung-vwa-ev.pdf"),
      isLoading: false,
      stepControl: null,
      formId: null,
      subStepsControls: {},
      services: [],
      isPrevStepDisabled: true,
      steps: [
        {
          id: 'vorschlag',
          number: 1,
          title: 'Vorschlag',
          active: true,
          visible: true,
          visited: true,
          component: markRaw(vorschlagStep)
        },
        {
          id: 'abfrage-gesundheit',
          title: 'Gesundheitsfragen',
          active: false,
          visible: true,
          visited: false,
          component: markRaw(abfrageGesundheitStep)
        },
        {
          id: 'gesundheit',
          number: 2,
          title: 'Gesundheit',
          active: false,
          visible: true,
          visited: false,
          component: markRaw(gesundheitStep)
        },
        {
          id: 'versicherung',
          number: 3,
          title: 'Vorversicherung',
          active: false,
          visible: true,
          visited: false,
          component: markRaw(versicherungStep)
        },
        {
          id: 'vorschlag-umsetzen',
          number: 4,
          title: 'Vorschlag umsetzen',
          active: false,
          visible: true,
          visited: false,
          component: markRaw(vorschlagUmsetzenStep)
        }
      ],
      form: {
        gesundheitsfragen: {}
      },
      isProcessing: false,
      isComplete: false
    }
  },

  created() {
    const apiClient = new ApiClient();
    this.isLoading = true;
    this.formId = config.formId;
    apiClient.getFormConfiguration(this.formId)
      .then(({data}) => {
        this.services = [];
        for(let i = 0; i < data.services.length; i++) {
          this.services.push({
            ...{ id: i },
            ...data.services[i]
          });
        }

        this.stepControl = new StepControl('main', this.steps);
        this.stepControl.setCurrentStepId('vorschlag');
        this.stepControl.getEmitter().on('step-changed', this._onStepChanged.bind(this));
        for(let s in this.steps) {
          const step = this.steps[s];
          if(typeof step.steps == 'undefined') {
            continue;
          }
          this.subStepsControls[step.id] = new StepControl(step.id, step.steps);
        }
        this.isLoading = false;
      });

    /*this.stepControl = new StepControl('main', this.steps);
    this.stepControl.getEmitter().on('step-changed', this._onStepChanged.bind(this));
    for(let s in this.steps) {
      const step = this.steps[s];
      if(typeof step.steps == 'undefined') {
        continue;
      }
      this.subStepsControls[step.id] = new StepControl(step.id, step.steps);
    }*/
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const dataParam = urlParams.get('data');
    if(!dataParam) {
      return;
    }
    try {
      const data = JSON.parse(dataParam);
      this.form = { person: {}, vermittler: {}, gesundheitsfragen: {} };
      if(typeof data.anrede!== 'undefined') {
        switch(data.anrede.toLowerCase()) {
          case 'herr':
            this.form.person.anrede = 'männlich';
            break;
          case 'frau':
            this.form.person.anrede = 'weiblich';
            break;
        }
      }
      if(typeof data.titel !== 'undefined') {
        this.form.person.titel = data.titel;
      }
      if(typeof data.vorname !== 'undefined') {
        this.form.person.vorname = data.vorname;
      }
      if(typeof data.nachname !== 'undefined') {
        this.form.person.nachname = data.nachname;
      }
      if(typeof data.email !== 'undefined') {
        this.form.person.email = data.email;
      }
      if(typeof data.tel !== 'undefined') {
        this.form.person.telefon = data.tel;
      }
      if(typeof data.str !== 'undefined') {
        this.form.person.strasse = data.str;
      }
      if(typeof data.hsnr !== 'undefined') {
        this.form.person.hausnummer = data.hsnr;
      }
      if(typeof data.plz !== 'undefined') {
        this.form.person.plz = data.plz;
      }
      if(typeof data.ort !== 'undefined') {
        this.form.person.ort = data.ort;
      }
      if(typeof data.beruf !== 'undefined') {
        this.form.person.taetigkeit = data.beruf;
      }
      if(typeof data.geburtstag !== 'undefined') {
        const dateParts = data.geburtstag.split('.');
        if(dateParts.length === 3) {
          this.form.birthDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
        }
      }
      if(typeof data.vermittler !== 'undefined') {
        this.form.vermittler.typ = 'eigener';
        this.form.vermittler.name = data.vermittler;
      }
      if(typeof data.vmnr !== 'undefined') {
        this.form.vermittler.typ = 'eigener';
        this.form.vermittler.number = data.vmnr;
      }
      if(typeof data.vmail !== 'undefined') {
        this.form.vermittler.typ = 'eigener';
        this.form.vermittler.email = data.vmail;
      }
      if(typeof data.vtel !== 'undefined') {
        this.form.vermittler.typ = 'eigener';
        this.form.vermittler.telefon = data.vtel;
      }
      if(typeof data.referenz !== 'undefined') {
        this.form.vermittler.typ = 'eigener';
        this.form.vermittler.referenz = data.referenz;
      }
      console.log('data', data);
    } catch (e) {
      console.error('error', e);
    }

  },

  methods: {
    goToStep(stepId) {
      this._handleGoToNextStep({ goToStep: stepId });
    },
    onUpdatePrevStepDisabled(isDisabled) {
      this.isPrevStepDisabled = isDisabled;
    },
    goToNextStep() {
      this.$refs.form.validate()
        .then((result) => {
          if(!result.valid) {
            const firstAlertElement = document.querySelector('span[role="alert"]');
            if(firstAlertElement) {
              this._scrollToElement(firstAlertElement);
            }
            return;
          }
          if(this.$refs.currentComponent && typeof this.$refs.currentComponent.goToNextStep == 'function') {
           const goToNextStepResult = this.$refs.currentComponent.goToNextStep(this.stepControl, this.currentSubStepControl || null);
           if(typeof goToNextStepResult == 'object' && goToNextStepResult instanceof Promise) {
             goToNextStepResult.then((result) => {
               this._handleGoToNextStep(result);
             });
           } else if(typeof goToNextStepResult == 'boolean') {
             if (goToNextStepResult === true) {
               this._handleGoToNextStep({ goToNextStep: true });
             }
           } else if(typeof goToNextStepResult == 'object') {
             this._handleGoToNextStep(goToNextStepResult);
           } else if(typeof goToNextStepResult == 'string') {
             this._handleGoToNextStep({ goToStep: goToNextStepResult });
           } else {
             console.warn('unexpected result: ', goToNextStepResult);
           }
           window.scrollTo(0, 0);
           window.parent.postMessage({action: 'scrollTop'}, '*');
          } else {
            this._handleGoToNextStep({ goToNextStep: true });
          }
        });
    },
    goToPrevStep() {
      if(this.$refs.currentComponent && typeof this.$refs.currentComponent.goToPrevStep == 'function') {
        const prevStepResult = this.$refs.currentComponent.goToPrevStep(this.stepControl, this.currentSubStepControl || null);
        if(typeof prevStepResult == 'object' && prevStepResult instanceof Promise) {
          prevStepResult.then(() => {
            this._handleGoToPrevStep();
          });
        } else if(prevStepResult === true) {
          this._handleGoToPrevStep();
        }
        window.scrollTo(0, 0);
        window.parent.postMessage({action: 'scrollTop'}, '*');
      } else {
        this._handleGoToPrevStep();
      }
    },
    submitForm() {
      this.$refs.form.validate()
        .then((result) => {
          if (!result.valid) {
            const firstAlertElement = document.querySelector('span[role="alert"]');
            if(firstAlertElement) {
              this._scrollToElement(firstAlertElement);
            }
            return;
          }
          this.isProcessing = true;
          const apiClient = new ApiClient();
          apiClient.submitForm(this.formId, this.form)
            .then((result) => {
              this.isProcessing = false;
              this.isComplete = true;
              window.parent.postMessage({action: 'scrollTop'}, '*');
            });
        });
    },
    showZahlbeitrag() {
      if(this.displayStep.id === 'vorschlag') {
        if(this.$refs.currentComponent && typeof this.$refs.currentComponent.showZahlbeitrag == 'function') {
          return this.$refs.currentComponent.showZahlbeitrag();
        }
      }
      return true;
    },
    loadDemoData() {
      this.form = {
        "gesundheitsfragen": {
          "G6_1_Person1": "nein",
          "G6_2_Person1": "nein",
          "G6_3_Person1": "ja",
          "G6_4_Person1": "nein",
          "G6_5_Person1": "nein",
          "G6_6_Person1": "nein",
          "G6_7_Person1": "nein",
          "G6_8_Person1": "nein",
          "G6_9_Person1": "nein",
          "Behinderung_Person1": null,
          "G6_10_Person1": "ja",
          "Schwangerschaftsmonat_Person1": 2,
          "Groesse_Person1": 170,
          "Gewicht_Person1": 100,
          "G6_13_Person1": "ja",
          "Zahnverlust_Person1": 3,
          "G6_14_Person1": "nein",
          "Details_G11": {
            "G6_1_Person1": {
              "details": 1,
              "show": false,
              "showHinweis": false
            },
            "G6_2_Person1": {
              "details": 2,
              "show": false,
              "showHinweis": false
            },
            "G6_3_Person1": {
              "details": 3,
              "show": true,
              "showHinweis": false,
              "von": "2000-01-01",
              "bis": "2010-05-01",
              "behandlungsfrei": "ja",
              "behandlungsfreiSeit": "2025-06-01",
              "ausfuehrung": "Test",
              "auskunft": "Test\nTest"
            },
            "G6_4_Person1": {
              "details": 4,
              "show": false,
              "showHinweis": false
            },
            "G6_5_Person1": {
              "details": 5,
              "show": false,
              "showHinweis": false
            },
            "G6_6_Person1": {
              "details": 6,
              "show": false,
              "showHinweis": false
            },
            "G6_7_Person1": {
              "details": 7,
              "show": false,
              "showHinweis": false
            },
            "G6_8_Person1": {
              "details": 8,
              "show": false,
              "showHinweis": false
            },
            "G6_9_Person1": {
              "details": 9,
              "show": false,
              "showHinweis": false
            },
            "G6_10_Person1": {
              "details": 10,
              "show": true,
              "showHinweis": false
            },
            "G6_11_Person1": {
              "details": 11,
              "show": false,
              "showHinweis": false
            },
            "G6_12_Person1": {
              "details": 12,
              "show": false,
              "showHinweis": false
            },
            "G6_13_Person1": {
              "details": 13,
              "show": true,
              "showHinweis": false
            },
            "G6_14_Person1": {
              "details": 14,
              "show": false,
              "showHinweis": false
            }
          },
          "G6_11_Person1": "nein",
          "G6_12_Person1": "nein"
        },
        "services": {
          "0": {
            "id": 0,
            "show": false,
            "default": true,
            "sickPay": 200,
            "startDay": "43",
            "selected": true,
            "contribution": 91.6,
            "coverage": 6000,
            "totalSickPay": 200,
            "totalContribution": 91.6,
            "totalCoverage": 6000
          },
          "1": {
            "id": 1,
            "show": false,
            "default": false,
            "sickPay": 220,
            "startDay": "92",
            "selected": true,
            "contribution": 54.56,
            "coverage": 6600,
            "totalSickPay": 420,
            "totalContribution": 146.16,
            "totalCoverage": 12600
          },
          "2": {
            "id": 2,
            "show": false,
            "default": false,
            "sickPay": 100,
            "startDay": "183",
            "selected": true,
            "contribution": 12,
            "coverage": 3000,
            "totalSickPay": 520,
            "totalContribution": 158.16,
            "totalCoverage": 15600
          }
        },
        "birthDate": "1986-06-26",
        "age": 37,
        "taetigkeit": "arbeitnehmer",
        "krankengeldBestandskunde": "ja",
        "infoText": "Für 158,16 EUR pro Monat erhalten Sie <ul><li>ab dem 43. Tag ein KTG in Höhe von 200,00 €</li><li>zusätzlich  ab dem 92. Tag ein KTG in Höhe von 220,00 €</li><li>zusätzlich ab dem 183. Tag ein KTG in Höhe von 100,00 €.</li></ul><i class='bi bi-caret-right-fill'></i>Dies entspricht einer monatlichen Absicherung von 15.600,00 €.",
        "totalContribution": 158.16,
        "totalCoverage": 15600,
        "gesundheitsfrage": "jetzt",
        "person": {
          "anrede": "männlich",
          "vorname": "Tobias",
          "nachname": "Herweg",
          "strasse": "Stolberger Straße",
          "hausnummer": "368",
          "plz": "50933",
          "ort": "Köln",
          "email": "tobias.herweg@11ecom.de",
          "natonalitaet": "Deutsch",
          "facharzt": "Dr. Max Mustermann",
          "taetigkeit": "Programmierer",
          "telefon": "0221 123456",
          "einkommen": "3000",
          "entgeletortzahlung": "ja",
          "entgeletortzahlungTage": 60
        },
        "arbeitgeber": {
          "name": "11ecom GmbH",
          "plz": "50933",
          "ort": "Köln",
          "strasse": "Stolberger STraße 368"
        },
        "konto": {
          "iban": "944448484848484",
          "bic": "848484",
          "beitragszahlung": "monatlich"
        },
        "customerType": "KTC",
        "vermittler": {
          "typ": "eigener",
          "name": "Max Mustermann",
          "number": "854848488",
          "email": "max.mustermann@11ecom.de"
        },
        "versicherung": {
          "typ": "gesetzlich",
          "krankengeld": "ja",
          "krankengeldHoehe": "555",
          "name": "test",
          "beginn": "2023-09-01"
        }
      };
    },
    _handleGoToNextStep(result) {
      const currentSubStep = this.currentSubStepControl?.getCurrentStep() || null;
      const currentStepControl = currentSubStep && !currentSubStep.lastStep ? this.currentSubStepControl : this.stepControl;
      const currentStepIndex = currentStepControl.getCurrentStepIndex();
      if(result.goToNextStep) {
        currentStepControl.pushStepToHistory();
        currentStepControl.setCurrentStepIndex(currentStepIndex + 1);
      } else if(result.goToStep) {
        const stepIndex = currentStepControl.findStepIndex(result.goToStep);
        if(stepIndex >= 0) {
          currentStepControl.pushStepToHistory();
          currentStepControl.setCurrentStepIndex(stepIndex);
        }
      }
      window.scrollTo(0, 0);
      window.parent.postMessage({action: 'scrollTop'}, '*');
    },
    _handleGoToPrevStep() {
      const currentSubStep = this.currentSubStepControl?.getCurrentStep() || null;
      if(currentSubStep && !currentSubStep.firstStep) {
        this.currentSubStepControl.goToPrevStep();
        window.scrollTo(0, 0);
        window.parent.postMessage({action: 'scrollTop'}, '*');
        return;
      }
      this.stepControl.goToPrevStep();
      window.scrollTo(0, 0);
      window.parent.postMessage({action: 'scrollTop'}, '*');
    },
    _onStepChanged({ step }) {

    },
    _scrollToElement(element) {
      const formField = element.closest('.form-field');
      const fixedProgressbar = document.getElementById('fixed-progressbar');
      let y = (formField ? formField : element).getBoundingClientRect().top + window.scrollY;
      if(fixedProgressbar) {
        y-= fixedProgressbar.getBoundingClientRect().height;
      }
      y-= 110;
      /*window.scroll({
        top: y,
        behavior: 'smooth'
      });*/
      window.parent.postMessage({action: 'scrollTo', top: y}, '*');
    }
  }
}
</script>