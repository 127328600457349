<template>

  <div class="container-fluid g-0">
    <vwa-form>
    </vwa-form>
  </div>

</template>

<script>

export default {
  name: 'vwagruppe-app'
}
</script>